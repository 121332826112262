section.read-more-links {
    padding-bottom: 7rem;
    position: relative;
    z-index: 2;

    @media(min-width: $tablet-BP) {
        padding-bottom: 5rem;
    }
    @media(min-width: $desktop-BP) {
        padding-bottom: 17rem;
    }

    & > div {
        padding: 0.7rem 0;
        @media(min-width: $tablet-BP) {
            padding: 4rem 0;
        }
        
        ul {
            list-style-type: none;
            
            li {
                display: flex;
                flex-direction: column;
                border-bottom: 1px solid $dark-mustard;
                padding: 3rem 0;
                color: $white;
                
                @media(min-width: $tablet-BP) {
                    padding: 4rem 0;
                    width: 95%;
                }

                p {
                    margin-top: 0;
                    margin-top: -0.9rem;

                    @media(min-width: $desktop-BP) {
                        line-height: 1.2;
                    }
                }

                a {
                    font-family: $ff-serif;
                    text-transform: uppercase;
                    font-size: 1.4rem;
                    color: $white;
                }

                &:first-child {
                    padding-top: 0;
                }

                &:last-child {
                    margin-bottom: 5rem;
                }
            }
        }
        
        .button-container {
            .generic-button {
                max-width: 23rem;
            }
        }
    }
}