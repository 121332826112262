section.why-us-hero {

    background: $dark-gray;
    position: relative;
    padding-top: 87px;

    @media screen and (min-width: $tablet-BP) {
        padding-top: 0;
    }
    
    &::before {
        content: "";
        position: absolute;
        background:url(/assets/img/hero-stairs-bg.png) no-repeat top left;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        background-size: 372px;

        @media screen and (min-width: $mobile-BP) {
            background-size: 690px;
        }
        @media screen and (min-width: $tablet-BP) {
            background-size: 547px;
            margin-top: 0;
        }
        @media screen and (min-width: $desktop-BP) {
            background-size: 700px;
        }
    
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 0px 0px;
        grid-template-areas:
        ". text text ."
        ". text text .";
        @media screen and (min-width: $tablet-BP) {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-template-rows: 0.7fr 1.3fr;
            grid-template-areas:
            ". . nav nav nav nav"
            ". . . text text text";
        }

        p {
            grid-area: text;
            padding-bottom: 3rem; //new
            color: $white;
            position: relative;

            @media screen and (min-width: $tablet-BP) {
                margin-right: 1rem;
            }
            
            @media screen and (min-width: $desktop-BP) {
                border-bottom: 16px solid transparent;
                margin-right: 5rem;
                margin-bottom: 11rem;
            }

            span {
                background: $dark-gradient;
                height: 1rem;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
    
                @media screen and (min-width: $desktop-BP) {
                    height: 1.7rem;
                }
            }
        } 
    }
}