section.hero-service-detail {
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
    background-color: $dark-gray;
    position: relative;
    padding-top: 87px;
    color: $white;

    @media screen and (min-width: $tablet-BP) {
        margin-top: 0;
        padding-top: 0;
    }

    @media screen and (min-width: $desktop-BP) {
        background-size: auto 100%;
    }

    .grid {
        @media screen and (min-width: $tablet-BP) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2.8fr;
            grid-template-rows: 0.7fr 1.3fr;
            gap: 0px 0px;
            grid-template-areas:
            "bg bg bg bg bg ."
            "text text text text text .";
        }

        @media screen and (min-width: $desktop-BP) {
            grid-template-columns: 0.6fr 1.4fr 1fr 1fr 1.4fr 0.6fr;
            grid-template-rows: 1fr 1fr;
            grid-template-areas:
            ". . . bg bg ."
            ". text text bg bg .";
        }

        & > div {
            @media screen and (min-width: $tablet-BP) {
                grid-area: text;
            }

            h4 {
                margin: 0;
            }

            p {
                margin: 0;
                padding-top: 4rem;
                position: relative;

                @media screen and (min-width: $tablet-BP) {
                    margin-bottom: 8rem;
                }

                &:before {
                    content: "";
                    position: absolute;
                    background: $dark-gradient;
                    padding-bottom: 0.2rem;
                    width: 92%;
                    margin-top: -2rem;
                }
            }
        }
    }
}