article.challenges-card { 

    & > div {

        margin: 0;
        @media screen and (min-width: $tablet-BP) {
            margin-top: 2rem;
            margin-bottom: 8rem;
        }

        .grid {
            padding-top: 0;
            @media screen and (min-width: $tablet-BP) {
                display: grid;
                grid-template-columns: 1fr 2fr;
                grid-template-rows: 1fr;
                gap: 0px 0px;
            }
            @media screen and (min-width: $desktop-BP) {
                display: grid;
                grid-template-columns: 2fr 2.5fr;
                grid-template-rows: 1fr;
                gap: 0px 0px;
            }

            & > div {
                &:first-child {
                    margin-bottom: 3rem;
                    @media screen and (min-width: $tablet-BP) {
                        margin-bottom: 0;
                        padding: 0 2rem 0 0;
                    }

                    figure {
                        margin: 5rem 0 0 0;
                        position: relative;
                        max-height: 30rem;
                        overflow: hidden;
        
                        @media screen and (min-width: $mobile-BP) {
                            max-height: 35rem;
                            margin: 0;
                        }
                        @media screen and (min-width: $tablet-BP) {
                            max-height: none;
                        }
                        
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                        & > span {
                            background: $dark-gradient;
                            height: 2rem;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            width: 100%;
                            @media screen and (min-width: $tablet-BP) {
                                height: 3rem;  
                            }
                            
                            @media screen and (min-width: $desktop-BP) {
                                height: 4rem;
                            }
                        }
                    }
                }

                &:last-child {
                    display:flex;
                    flex-direction: column;
                    justify-content: start;
                    align-items: flex-start;

                    @media screen and (min-width: $tablet-BP) {
                        justify-content: center;
                    }
                    
                    @media screen and (min-width: $desktop-BP) {
                        padding-left: 5.5rem;
                    }

                    h4 {
                        margin:0;
                        text-transform: uppercase;
                    }

                    p {
                        margin-bottom: 3rem;
                        @media screen and (min-width: $desktop-BP) {
                            margin-bottom: 4rem;
                        }
                    }

                    a {
                        @media screen and (min-width: $desktop-BP) {
                            max-width: max-content ;
                        }
                    }
                }
            }
        }  
    }
}