section.challenges-cards {
    margin-bottom: 5em;

    @media screen and (min-width: $desktop-BP) { 
        margin-bottom: 12rem;
    }

    & div {
        padding: 0;
        
    }
}