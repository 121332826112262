section.hero-contact {
    & > div {
        padding: 15rem 0 0 0;
        
        @media screen and (min-width: $desktop-BP) {
            padding-top: 24rem;
            padding-bottom: 16rem
        }

        .grid {
            @media screen and (min-width: $tablet-BP) { 
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: 1fr;
                gap: 0px 0px;
                grid-template-areas:
                "animation text text";
                padding: 0;
            }

            @media screen and (min-width: $desktop-BP) {
                grid-template-columns: 1.1fr 1.2fr 0.1fr 1.4fr 1.1fr;
                grid-template-rows: 1fr 1fr 1fr;
                grid-template-areas:
                "animation animation . text text"
                "animation animation . text text"
                "animation animation . text text";
                padding: 0 0 4rem 0;
            }
            
                position: relative;

            & > div {
                &:first-child {
                    grid-area: animation;
                    .line {
                        @media screen and (min-width: $tablet-BP) {
                            position: absolute;
                            width: 18px;
                            border: 1px solid $dark-mustard;
                            transform: translateX(-50%);
                        }
                    }
        
                    .line-1 {
                        @media screen and (min-width: $tablet-BP) {
                            top: -16%;
                            left: 8%;
                            height: 473px;
                            transform: rotate(-22deg);
                            
                        }

                        @media screen and (min-width: $desktop-BP) {
                            top: -49%;
                            left: 11%;
                            height: 747px;
                        }
                    }

                    .line-2 {
                        @media screen and (min-width: $tablet-BP) {
                            top: -69%;
                            left: 8%;
                            height: 323px;
                            transform: rotate(48deg);
                        }

                        @media screen and (min-width: $desktop-BP) {
                            top: -78%;
                            left: 8%;
                            height: 690px;
                            transform: rotate(48deg);
                        }
                    }

                    .line-3 {
                        @media screen and (min-width: $tablet-BP) {
                            top: -29%;
                            left: 6%;
                            height: 320px;
                            transform: rotate(230deg);
                            
                        }
                        @media screen and (min-width: $desktop-BP) {
                            height: 700px;
                            top: -61%;
                            left:10%;
                        }
                    }
                }

                &:last-child {
                    grid-area: text;
                    
                    @media screen and (min-width: $desktop-BP) { 
                        padding-right: 3.5rem;
                    } 
                    
                    & > div {
                        &:first-child {
                            p {
                                margin-bottom: 0;
                                @media screen and (min-width: $tablet-BP) { 
                                    margin: 0;
                                }
                            }

                            & > div {
                        
                                @media screen and (min-width: $tablet-BP) { 
                                    display: grid;
                                    grid-template-columns: 1fr 1fr;
                                }

                                h4 {
                                    text-transform: uppercase;
                                    margin-bottom: 0.5rem;
                                    margin-top: 0;
                                    font-size: $ff-serif;
                                    @media screen and (min-width: $tablet-BP) { 
                                        margin-bottom: 2rem;
                                    }
                                }

                                a {
                                    text-decoration: none;
                                }

                                & > div {
                                    a {
                                            font-size:2.2rem; 
                                            @media screen and (min-width: $tablet-BP) { 
                                                font-size: 2.5rem;
                                            }
                                        } 
                                    &:first-child {

                                        margin-bottom: 1rem;

                                        @media screen and (min-width: $tablet-BP) { 
                                            margin-bottom: 0;
                                        }

                                        // a {
                                        //     font-size:1.5rem; 
                                        //     @media screen and (min-width: $tablet-BP) { 
                                        //         font-size: 2.5rem;
                                        //     }  
                                        // } 
                                    }
                                    // &:last-child {
                                    //     a {
                                    //         font-size:1.5rem; 
                                    //         @media screen and (min-width: $tablet-BP) { 
                                    //             font-size: 2.5rem;
                                    //         }
                                    //     } 
                                    // }
                                }
                            }

                            & > span {
                                background: $dark-gradient;
                                height: 2rem;
                                margin: 3rem 0;
                                display: inline-block;
                            }
                        }
                    }
                }
            } 
        }
    }
}