section.blog-internal {
    background-color: $bg-gray;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 85%;
    position: relative;

    @media screen and (min-width: $desktop-BP) {
        background-size: 35%;		
    }

    &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        background: $grad-gray-blog;
        height: 100%;
        z-index: 0;

        @media screen and (min-width: $tablet-BP) {		
            top: 7rem;
        }

        @media screen and (min-width: $desktop-BP) {		
            top: 11rem;
        }
    }
}