section.hero-blog {
    & > div {
        padding-top: 15rem;
        
        @media screen and (min-width: $desktop-BP) {
            padding-top: 24rem;
            padding-bottom: 12rem
        }

        .grid {
            @media screen and (min-width: $tablet-BP) { 
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: 1fr;
                gap: 0px 0px;
                grid-template-areas:
                "animation text text";
            }

            @media screen and (min-width: $desktop-BP) {
                grid-template-columns: 1.1fr 1.2fr 0.1fr 1.4fr 1.1fr;
                grid-template-rows: 1fr 1fr 1fr;
                grid-template-areas:
                "animation animation . text text"
                "animation animation . text text"
                "animation animation . text text";
            }

            & > div {
                &:first-child {
                    grid-area: animation;
                    position: relative;
                }

                &:last-child {
                    grid-area: text;
                    
                    @media screen and (min-width: $desktop-BP) { 
                        padding-right: 3.5rem;
                    } 
                    
                    & > div {
                        &:first-child {
                        
                            h4 {
                                padding-bottom: 3.5rem;
                                margin: 0;
                                color: $white;
                            }

                            p {
                                padding-bottom: 4rem;
                                margin: 0;
                                color: $white;
                            } 
                        }
                    }

                    & > span {
                        display: inline-block;
                        width: 100%;
                        height: 1.8rem;
                        background: $dark-gradient;
                    }
                }
            }
        }
    }
}
