section.our-expertise-service-detail {
    background-color: $soft-bg-mustard;
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: contain;
    padding-bottom: 30rem;

    @media screen and (min-width: $tablet-BP) {
        padding-bottom: 0;
        background-size: 51%;
    }

    @media screen and (min-width: $desktop-BP) {
        background-size: 728px;
        padding-top: 6rem;
        padding-bottom: 0;
    }

    @media screen and (min-width: $desktop-big-BP) {
        background-size: 842px;
        padding-top: 8rem;
    }

    .grid {
        
        @media screen and (min-width: $tablet-BP) {  
            display: grid;
            grid-template-columns:1fr;
            grid-template-rows: 1fr 1fr;
            gap: 0px 0px;
            grid-template-areas:
            "area1 area2"
            ". area2";
        }

        @media screen and (min-width: $desktop-BP) {  
            grid-template-columns: 1fr 1fr;
            padding-bottom: 1rem;
        }

        & > div:first-child {
            
            @media screen and (min-width: $tablet-BP) {  
                grid-area: area1;
            }

            @media screen and (min-width: $tablet-BP) {  
                padding: 0 3rem;
            }

            @media screen and (min-width: $desktop-big-BP) {  
                padding: 0 5rem 0 11rem;
            }

            & > h3:last-child {
                padding-top: 1rem;
                margin: 0;
            }


            h3 {
                margin-right: 6rem;
                padding-bottom: 1rem;
                text-transform: uppercase;
                
                @media screen and (min-width: $mobile-small-BP) { 
                    margin-right: 24rem;
                }
                @media screen and (min-width: $mobile-BP) { 
                    margin-right: 31rem;
                }
                @media screen and (min-width: $tablet-BP) { 
                    margin-right: 0;
                }

                @media screen and (min-width: $desktop-BP) { 
                    margin-right: 3rem;
                    line-height: 55px;
                }
            }
        }
    
        & > div:last-child {
            grid-area: area2;
            padding-top: 2rem;
        
            @media screen and (min-width: $tablet-BP) { 
                padding: 0 2rem;
            }
            
            @media screen and (min-width: $desktop-BP) { 
                padding: 0px 5.5rem;
            }
            ul {
                list-style-type: none;

                h4 {
                    color: $dark-mustard;
                    text-transform: uppercase; 
                }

                p {
                    margin-top:5px;
                }

                li {
                    padding-bottom:2rem;
                }
            } 
        }
    }
}