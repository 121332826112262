/*----------------------------------------------------------------*\
		MAIN FOOTER
\*----------------------------------------------------------------*/

footer.main-footer {
	background-color: $dark-gray;
	padding-top: 4rem;
	padding-bottom: 4rem;
	position: relative;
	
	@media screen and (min-width: $tablet-BP) {
		padding: 5rem 3rem;
	}

	@media screen and (min-width: $desktop-big-BP) {
		padding-top: 7rem;
		padding-bottom: 3rem;
	}

	.grid {
		text-align: center;
		max-width: 1600px;
		margin: 0 auto;
		padding: 2rem;

        @media screen and (min-width: $desktop-big-BP) {
			display: grid;
			grid-template-columns: 2fr 6fr;
			gap: 10rem;
		}

		.main-footer-logo {
			display: none;
			@media screen and (min-width: $desktop-big-BP) {
				display: block;
			}
			img {
				height: 90%;
				width: auto;
				filter: invert(27%);
			}
		}
		
        .main-footer-content {
			padding: 2rem;
			text-align: left;
			

			@media screen and (min-width: $desktop-big-BP) {
				padding: 0;
				
			}

			h4 {
				margin-top: 0;
				margin-bottom: 0;
			}

			p {
				color: $white;
				text-align: left;
				
				@media screen and (min-width: $tablet-BP) {
					padding: 0;
				}

				@media screen and (min-width: $desktop-big-BP) {
					margin: 0;
				}
			}

			ul {
				list-style-type: none;
				display: flex;
				align-items: center;
				flex-wrap: wrap;

				li {
					color:$soft-gray;
					display: inline-flex;
					width: 40%;
					padding: 1.4rem 0;
					
					@media screen and (min-width: $tablet-BP) {
						display: inline-block;
						width: auto;
						padding: 2.5rem 0;
					}
					@media screen and (min-width: $desktop-big-BP) {
						display: inline-block;
						padding-right: 0;
						margin-bottom: 3.25rem;
						margin-top: 1.2rem;
					}
				}
			}

			a {
				text-decoration: none;
				text-transform: uppercase;
			}

			& > div {
				line-height: 0;
				&:nth-child(2) {
					@media screen and (min-width: $tablet-BP) {
						padding: 2rem 0 3rem 0;
					}
					@media screen and (min-width: $desktop-big-BP) {
						padding:0;
						margin: 4rem 0 0rem 0;
						padding: 0;
					}

					a {
						padding-right: 2rem;
					}
				}
				
			}

			.logos {
				margin-bottom: 4rem;
				@media screen and (min-width: $desktop-big-BP) {
					margin-bottom: 4rem;
				}

				& > div {
					@media screen and (min-width: $desktop-big-BP) {
						display: flex;
						align-items: center;
						.social {
							a {
								img {
									height: 3rem;
									width: auto;
								}
							}
						}
					}

					.certifications {
						// display: none;
						display: flex;
						//uncomment the flex when they approve the logos
						align-items: center;
						justify-content: flex-start;
						margin-top: 3rem;
						@media screen and (min-width: $tablet-BP) {
							margin-top: 5rem;
						}
						@media screen and (min-width: $desktop-big-BP) {
							margin-top: 0;
							margin-left: 3rem;
						}
						img {
							max-width: 40%;
							height: auto;
	
							&:last-child {
								margin-left: 2rem;
							}
							@media screen and (min-width: $tablet-BP) {
								max-width: 16rem;
							}
							@media screen and (min-width: $desktop-big-BP) {
								max-width: 18rem;
							}
						}
					}
				}

			}

			.credits {
				color:$soft-gray;
				display: inline;
				font-size: 1.2rem;
				line-height: 2rem;

				a {
					color:$soft-gray;
				}
			}
        }
    }
}