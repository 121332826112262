section.services-let-us-serve {

    & > div {
        padding-top: 15rem;
        
        @media screen and (min-width: $desktop-BP) {
            padding-top: 24rem;
            padding-bottom: 12rem
        }

        .grid {
            @media screen and (min-width: $tablet-BP) { 
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: 1fr;
                gap: 0px 0px;
                grid-template-areas:
                "animation text text";
            }

            @media screen and (min-width: $desktop-BP) {
                grid-template-columns: 1.1fr 1.2fr 0.1fr 1.4fr 1.1fr;
                grid-template-rows: 1fr 1fr 1fr;
                grid-template-areas:
                "animation animation . text text"
                "animation animation . text text"
                "animation animation . text text";
            }

            & > div {
                &:first-child {
                    grid-area: animation;
                    position: relative;
                }

                &:last-child {
                    grid-area: text;
                    
                    @media screen and (min-width: $desktop-BP) { 
                        padding-right: 3.5rem;
                    } 
                    
                    & > div {
                        &:first-child {
                        
                            h4 {
                                padding-bottom: 3.5rem;
                                margin: 0;
                            }

                            p {
                                padding-bottom: 4rem;
                                margin: 0;
                            } 
                        }

                        &:last-child {
                            h4 {
                                color: $dark-mustard;
                                margin: 0;
                                padding-top: 4rem;
                                padding-bottom: 1.7rem;
                                text-transform: uppercase;
                            }

                            p {
                                margin: 0;
                                padding-bottom: 3rem;
                            }
                        }
                    }

                    & > span {
                        display: inline-block;
                        width: 100%;
                        height: 1.8rem;
                        background: $dark-gradient;
                    }
                    
                }
                
            }

            .line {
                @media screen and (min-width: $tablet-BP) {
                    position: absolute;
                    width: 18px;
                    border: 1px solid $dark-mustard;
                    transform: translateX(-50%);
                }
            }

            .line-1 {
                @media screen and (min-width: $tablet-BP) {
                    top: -1%;
                    left: -38%;
                    height: 450px;
                    transform: rotate(-22deg);
                    
                }

                @media screen and (min-width: $desktop-BP) {
                    top: -5%;
                    left: 33%;
                    height: 747px;
                }
            }

            .line-2 {
                @media screen and (min-width: $tablet-BP) {
                    top: -3%;
                    left: 1%;
                    height: 421px;
                    transform: rotate(48deg);
                }

                @media screen and (min-width: $desktop-BP) {
                    top: -9%;
                    left: 13%;
                    height: 635px;
                    transform: rotate(48deg);
                }
            }

            .line-3 {
                @media screen and (min-width: $tablet-BP) {
                    top: 3%;
                    left: 18%;
                    height: 320px;
                    transform: rotate(77deg);
                    
                }
                @media screen and (min-width: $desktop-BP) {
                    height: 652px;
                }
            }
        }
    }
}