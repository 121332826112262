section.page-not-found {
    min-height: 50vh;
    display: flex;
    align-items: center;

    @media screen and (min-width: $desktop-BP) { 
        min-height: 80vh;
    }

    & > div {
        text-align: center;
        margin-top: 8rem;

        h2 {
            font-size: 4rem;
            @media screen and (min-width: $tablet-BP) { 
                font-size: 5rem;
            }
            @media screen and (min-width: $desktop-BP) { 
                font-size: 8rem;
            }
        }
        p {
            font-size: 2.5rem;
            @media screen and (min-width: $tablet-BP) { 
                font-size: 3rem;
            }
            @media screen and (min-width: $desktop-BP) { 
                font-size: 4rem;
            }
        }
    }
}