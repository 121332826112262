@charset "UTF-8";
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick-fonts/slick.eot");
  src: url("../fonts/slick-fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick-fonts/slick.woff") format("woff"), url("../fonts/slick-fonts/slick.ttf") format("truetype"), url("../fonts/slick-fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/****************** GLOBAL *********************/
@font-face {
  font-family: 'BwDarius-Regular';
  src: url("../fonts/webfonts/37C77E_2_0.eot");
  src: url("../fonts/webfonts/37C77E_2_0.eot?#iefix") format("embedded-opentype"), url("../fonts/webfonts/37C77E_2_0.woff2") format("woff2"), url("../fonts/webfonts/37C77E_2_0.woff") format("woff"), url("../fonts/webfonts/37C77E_2_0.ttf") format("truetype"); }

@font-face {
  font-family: 'BwDarius-Light';
  src: url("../fonts/webfonts/BwDariusDEMO-Light.eot");
  src: url("../fonts/webfonts/BwDariusDEMO-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/webfonts/BwDariusDEMO-Light.woff2") format("woff2"), url("../fonts/webfonts/BwDariusDEMO-Light.woff") format("woff"), url("../fonts/webfonts/BwDariusDEMO-Light.ttf") format("truetype"); }

/*-----------------------------------*\
    COLOR VARS TRANSPARENCIES
\*-----------------------------------*/
/*-----------------------------------*\
	CLASS COLORS
\*-----------------------------------*/
.white-c {
  color: #fff; }

.soft-gray {
  color: #858585; }

/*-----------------------------------*\
	MEDIA QUERIES IN CORRECT ORDER
\*-----------------------------------*/
/*-----------------------------------*\
	UNIVERSAL
\*-----------------------------------*/
html {
  box-sizing: border-box;
  font-size: 62.5%;
  font-family: "Josefin Sans", sans-serif;
  line-height: 1.1;
  scroll-behavior: smooth; }

html,
body {
  margin: 0;
  padding: 0;
  color: #252525; }

*,
*:before,
*:after {
  box-sizing: border-box; }

/*-----------------------------------*\
	HELPERS
\*-----------------------------------*/
.o-hidden {
  overflow: hidden; }

/*-----------------------------------*\
	SKIP NAV
\*-----------------------------------*/
#skip-to-content {
  position: absolute;
  top: 0px;
  left: 0;
  transform: translateY(-800%);
  z-index: 999999;
  transition: all .35s linear;
  padding: .5em 1em;
  text-decoration: none; }
  #skip-to-content:focus {
    transform: translateY(0); }

/*-----------------------------------*\
	SECTION
\*-----------------------------------*/
section {
  padding: 0 2em; }
  @media (min-width: 768px) {
    section {
      padding: 0 4em; } }
  section > div {
    padding: 4em 0;
    max-width: 1200px;
    margin: 0 auto; }
    @media (min-width: 768px) {
      section > div {
        padding: 4em 2em; } }
    @media (min-width: 1024px) {
      section > div {
        padding: 2em 2em; } }

/*-----------------------------------*\
	LIST
\*-----------------------------------*/
ol, ul {
  margin: 0;
  padding: 0; }
  ol li, ul li {
    padding: 0 0; }

/*-----------------------------------*\
	MARGIN
\*-----------------------------------*/
.mt-1 {
  margin-top: 1rem; }

.mt-2 {
  margin-top: 2rem; }

.mt {
  margin-top: 3rem; }
  @media screen and (min-width: 1024px) {
    .mt {
      margin-top: 7rem; } }

.mb {
  margin-bottom: 6.5rem; }

/*-----------------------------------*\
	CONTAINERS
\*-----------------------------------*/
@media screen and (min-width: 1024px) {
  .container-small {
    width: 80%;
    margin: 0 auto; } }

@media screen and (min-width: 1200px) {
  .container-small {
    width: 60%;
    margin: 0 auto; } }

/*-----------------------------------*\
	PLACEHOLDER
\*-----------------------------------*/
input::placeholder, input::-webkit-input-placeholder {
  color: #EFD6C0;
  font-size: 1.9rem;
  text-align: center;
  font-family: "BwDarius-Light", serif; }
  @media screen and (min-width: 768px) {
    input::placeholder, input::-webkit-input-placeholder {
      font-size: 2.5rem; } }
  @media screen and (min-width: 1200px) {
    input::placeholder, input::-webkit-input-placeholder {
      text-align: left;
      font-size: 2.2rem; } }

input[type="submit"] {
  appearance: none; }

/*-----------------------------------*\
	ICONS SOCIAL MEDIA
\*-----------------------------------*/
.ic-sm {
  filter: invert(50%);
  margin-top: 3rem;
  max-width: 2.5rem;
  margin-right: 2.6rem; }
  @media screen and (min-width: 768px) {
    .ic-sm {
      margin-right: 3rem;
      margin-top: 0; } }
  @media screen and (min-width: 1200px) {
    .ic-sm {
      margin-top: 0;
      max-width: 2.7rem;
      margin-right: 2rem; } }

/*-----------------------------------*\
	LOGOS
\*-----------------------------------*/
.dark-logo {
  max-width: 9rem;
  width: 12rem;
  height: auto; }
  @media screen and (min-width: 768px) {
    .dark-logo {
      max-width: 16rem;
      width: 13rem; } }
  @media screen and (min-width: 1200px) {
    .dark-logo {
      width: 15em;
      max-width: 15em; } }

/*-----------------------------------*\
	STICKY
\*-----------------------------------*/
@media screen and (min-width: 768px) {
  .sticky {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    top: 12rem;
    height: 300px;
    will-change: transform; }
    .sticky.sticky-small {
      height: 206px; }
    .sticky.sticky-inter {
      height: 267px; } }

@media screen and (min-width: 1024px) {
  .sticky {
    top: 5rem; } }

@media screen and (min-width: 1024px) {
  .sticky-desktop {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    top: 5rem;
    height: 300px;
    will-change: transform; }
    .sticky-desktop.sticky-small {
      height: 206px; }
    .sticky-desktop.sticky-inter {
      height: 267px; } }

@media screen and (min-width: 768px) {
  .sticky-desktop {
    scroll-margin-top: 16rem; } }

@media screen and (min-width: 1024px) {
  .sticky-desktop {
    scroll-margin-top: 17rem; } }

.hide-on-small-only {
  display: none; }
  @media (min-width: 768px) {
    .hide-on-small-only {
      display: block; } }

.hide-on-med-and-down {
  display: none; }
  @media (min-width: 1024px) {
    .hide-on-med-and-down {
      display: block; } }

.hide-on-large-and-up {
  display: block; }
  @media (min-width: 1024px) {
    .hide-on-large-and-up {
      display: none !important; } }

.hide-on-med-and-up {
  display: block; }
  @media (min-width: 768px) {
    .hide-on-med-and-up {
      display: none !important; } }

.show {
  display: none; }

/*-----------------------------------*\
	FOR ACCESSIBILITY
\*-----------------------------------*/
a:focus, button:focus, input:focus, select:focus, textarea:focus, h3:focus, .checkcontainer:focus {
  outline-color: #BF9454; }

.accessibility-hidden {
  visibility: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px; }

.cookies {
  display: none; }
  .cookies .card-cookies {
    width: calc(100% - 2rem);
    bottom: 0;
    left: 0;
    padding: 2rem;
    background: #fff;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 1rem;
    z-index: 3001;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); }
    @media screen and (min-width: 768px) {
      .cookies .card-cookies {
        width: 60%; } }
    @media screen and (min-width: 1024px) {
      .cookies .card-cookies {
        width: 50%; } }
    @media screen and (min-width: 1590px) {
      .cookies .card-cookies {
        width: 40%; } }
    @media screen and (min-width: 1600x) {
      .cookies .card-cookies {
        width: 30%; } }
    .cookies .card-cookies .header-cookies {
      font-weight: 300;
      margin-bottom: 10px; }
    .cookies .card-cookies .content-cookies p {
      font-size: 1.6rem;
      margin-top: 0; }
    @media screen and (min-width: 768px) {
      .cookies .card-cookies .actions-cookies {
        display: flex;
        align-items: center;
        justify-content: center; } }
    .cookies .card-cookies .actions-cookies a,
    .cookies .card-cookies .actions-cookies .negative-button,
    .cookies .card-cookies .actions-cookies .positive-button {
      margin: 0 0.5rem; }
      .cookies .card-cookies .actions-cookies a:first-child,
      .cookies .card-cookies .actions-cookies .negative-button:first-child,
      .cookies .card-cookies .actions-cookies .positive-button:first-child {
        margin-bottom: 1.5rem; }
      @media screen and (min-width: 768px) {
        .cookies .card-cookies .actions-cookies a,
        .cookies .card-cookies .actions-cookies .negative-button,
        .cookies .card-cookies .actions-cookies .positive-button {
          min-width: 15rem; }
          .cookies .card-cookies .actions-cookies a:first-child,
          .cookies .card-cookies .actions-cookies .negative-button:first-child,
          .cookies .card-cookies .actions-cookies .positive-button:first-child {
            margin-bottom: 0; } }
    .cookies .card-cookies .actions-cookies .negative-button a {
      background-color: #252525;
      color: #fff;
      padding: 18px 18px 13px;
      text-decoration: none;
      font-size: 1.4rem;
      font-weight: 700;
      letter-spacing: 2px;
      display: block;
      text-align: center;
      line-height: 1.4;
      font-family: "Josefin Sans", sans-serif;
      text-transform: uppercase; }
    .cookies .card-cookies .actions-cookies .positive-button a {
      background-color: #BF9454;
      color: #fff;
      padding: 18px 18px 13px;
      text-decoration: none;
      font-size: 1.4rem;
      font-weight: 700;
      letter-spacing: 2px;
      display: block;
      text-align: center;
      line-height: 1.4;
      font-family: "Josefin Sans", sans-serif;
      text-transform: uppercase; }

.right-hover {
  text-decoration: none;
  position: relative;
  display: inline-block;
  overflow: visible; }
  .right-hover::before {
    position: absolute;
    content: attr(data-content);
    top: 0;
    left: 0;
    width: 0;
    color: #BF9454;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    transition: width 250ms ease;
    display: inline-block; }
  @media screen and (min-width: 1024px) {
    .right-hover:hover {
      color: #BF9454 !important;
      transition: color 350ms ease; }
    .right-hover:hover::before {
      width: 100%; } }

.hvr-fade {
  -moz-transition: color .1s ease-in;
  -o-transition: color .1s ease-in;
  -webkit-transition: color .1s ease-in;
  transition: color .1s ease-in; }
  @media screen and (min-width: 1024px) {
    .hvr-fade:hover {
      color: #BF9454; } }

/* Underline From Left */
.hvr-underline-from-left > a {
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden; }
  .hvr-underline-from-left > a:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 100%;
    bottom: 0;
    background: linear-gradient(90deg, #b38141 13%, #bf9454 24%, #e4cf90 68%, #e4cf90 100%);
    height: 4px;
    -webkit-transition-property: right;
    transition-property: right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out; }

@media screen and (min-width: 1024px) {
  .hvr-underline-from-left:hover > a:before,
  .hvr-underline-from-left:focus > a:before,
  .hvr-underline-from-left:active > a:before {
    right: 0; } }

/* Underline From Left little */
.hvr-underline-from-left-little > a {
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden; }
  .hvr-underline-from-left-little > a:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 100%;
    bottom: 0;
    background: #fff;
    height: 1px;
    -webkit-transition-property: right;
    transition-property: right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out; }

@media screen and (min-width: 1024px) {
  .hvr-underline-from-left-little:hover > a:before,
  .hvr-underline-from-left-little:focus > a:before,
  .hvr-underline-from-left-little:active > a:before {
    right: 0; } }

.hvr-line {
  position: relative;
  display: inline-block;
  color: #252525;
  overflow: hidden; }
  .hvr-line::before {
    position: absolute;
    content: attr(data-content);
    top: 0;
    left: 0;
    width: 0;
    color: #BF9454;
    text-decoration: underline;
    white-space: nowrap;
    overflow: hidden;
    transition: width 575ms ease;
    display: inline-block; }
  @media screen and (min-width: 1024px) {
    .hvr-line:hover::before {
      width: 100%; } }
  .hvr-line.multiline::before {
    white-space: pre-wrap;
    text-indent: 1px;
    transition: width 200ms ease; }
  .hvr-line.change-color {
    color: #fff; }

.hvr-scale {
  transition: all .3s ease-in-out; }
  @media screen and (min-width: 1024px) {
    .hvr-scale:hover {
      transform: scale(1.03);
      transition: all .3s ease-in-out; } }

.hvr-svg-color {
  transition: all .2s ease-in-out; }
  @media screen and (min-width: 1024px) {
    .hvr-svg-color:hover {
      transition: all .2s ease-in-out;
      filter: invert(65%) sepia(24%) saturate(718%) hue-rotate(354deg) brightness(91%) contrast(84%); } }

/*-----------------------------------*\
	HEADER
\*-----------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  letter-spacing: 0;
  font-weight: 700;
  line-height: 1.1; }

h1,
.h1 {
  font-size: 40px; }
  @media (min-width: 768px) {
    h1,
    .h1 {
      font-size: 70px; } }

h2,
.h2 {
  font-size: 3rem; }
  @media screen and (min-width: 768px) {
    h2,
    .h2 {
      font-size: 3rem; } }
  @media screen and (min-width: 1024px) {
    h2,
    .h2 {
      font-size: 5.4rem; } }
  @media (min-width: 768px) {
    h2.is-big,
    .h2.is-big {
      font-size: 4.4rem; } }
  @media screen and (min-width: 1200px) {
    h2.is-big,
    .h2.is-big {
      font-size: 5.4rem; } }

h3,
.h3 {
  font-size: 30px; }
  @media (min-width: 768px) {
    h3,
    .h3 {
      font-size: 32px; } }
  @media (min-width: 1200px) {
    h3,
    .h3 {
      font-size: 50px; } }
  h3.is-extra,
  .h3.is-extra {
    font-size: 24px; }
    @media screen and (min-width: 400px) {
      h3.is-extra,
      .h3.is-extra {
        font-size: 25px; } }
    @media screen and (min-width: 400px) {
      h3.is-extra,
      .h3.is-extra {
        font-size: 23px; } }
    @media (min-width: 768px) {
      h3.is-extra,
      .h3.is-extra {
        font-size: 27px; } }
    @media (min-width: 1024px) {
      h3.is-extra,
      .h3.is-extra {
        font-size: 45px; } }

h4,
.h4 {
  font-size: 20px; }
  @media (min-width: 768px) {
    h4,
    .h4 {
      font-size: 26px; } }
  @media (min-width: 1024px) {
    h4,
    .h4 {
      font-size: 24px; } }
  h4.big,
  .h4.big {
    font-size: 23px; }
    @media (min-width: 768px) {
      h4.big,
      .h4.big {
        font-size: 30px; } }
    @media (min-width: 1024px) {
      h4.big,
      .h4.big {
        font-size: 33px; } }
    @media (min-width: 1024px) {
      h4.big.inter-big,
      .h4.big.inter-big {
        font-size: 41px; } }

h5,
.h5 {
  font-size: 16px; }
  @media (min-width: 768px) {
    h5,
    .h5 {
      font-size: 25px; } }

h6,
.h6 {
  font-size: 14px; }
  @media (min-width: 768px) {
    h6,
    .h6 {
      font-size: 16px; } }

/*-----------------------------------*\
	PARAGRAPH
\*-----------------------------------*/
p {
  font-size: 15px;
  line-height: 1.5;
  font-family: "BwDarius-Light", serif; }
  @media (min-width: 768px) {
    p {
      font-size: 18px; } }
  p.is-medium {
    font-size: 1.5rem; }
    @media (min-width: 768px) {
      p.is-medium {
        font-size: 1.7rem; } }
    @media (min-width: 1024px) {
      p.is-medium {
        font-size: 1.98rem; } }
  p.is-large {
    font-size: 1.5rem; }
    @media (min-width: 768px) {
      p.is-large {
        font-size: 22px; } }
  p.is-large-g {
    font-size: 1.8rem; }
    @media (min-width: 1024px) {
      p.is-large-g {
        font-size: 2.3rem; } }
  p.is-extra-large {
    font-size: 20px;
    line-height: 1.5; }
    @media (min-width: 768px) {
      p.is-extra-large {
        font-size: 50px;
        line-height: 1.2; } }
  p.is-large-bold {
    font-size: 17px;
    font-weight: 700; }
    @media (min-width: 768px) {
      p.is-large-bold {
        font-size: 28px; } }
  @media (min-width: 1024px) {
    p.is-long {
      font-size: 3.1rem; } }
  p.is-serif {
    font-family: "BwDarius-Light", serif; }
  p.is-sansSerif {
    font-family: "Josefin Sans", sans-serif; }
  p.is-serif-large {
    font-family: "BwDarius-Light", serif;
    font-size: 15px; }
    @media (min-width: 768px) {
      p.is-serif-large {
        font-size: 20px; } }
  p.is-serif-extra-large {
    font-family: "BwDarius-Light", serif;
    font-size: 21px; }
    @media (min-width: 768px) {
      p.is-serif-extra-large {
        font-size: 50px; } }
  p.is-bold {
    font-weight: bold; }
  @media (min-width: 768px) {
    p.is-cite {
      font-size: 23px; } }
  @media (min-width: 1024px) {
    p.is-large-medium {
      font-size: 2.4rem; } }
  @media screen and (min-width: 1024px) {
    p.text-big {
      font-size: 33px;
      line-height: 46px; } }

/*-----------------------------------*\
	EXTRAS FONT SIZE
\*-----------------------------------*/
.is-so-small {
  font-size: 14px; }

@media (min-width: 1024px) {
  .inter {
    font-size: 27px; } }

.large {
  font-size: 19px; }
  @media (min-width: 1024px) {
    .large {
      font-size: 28px; } }

@media (min-width: 1024px) {
  .medium-text {
    font-size: 2.2rem; } }

a, p a {
  color: #252525;
  text-decoration: underline; }

.pp {
  color: #fff; }

/*-----------------------------------*\
	HELPERS
\*-----------------------------------*/
.fw {
  font-weight: lighter; }

.fw-b {
  font-weight: bold; }

.ls {
  letter-spacing: 1px; }

.ls-2 {
  letter-spacing: 2px; }

.ls-4 {
  letter-spacing: 4px; }

.lh {
  line-height: 2.6rem; }
  @media screen and (min-width: 768px) {
    .lh {
      line-height: 3.3rem; } }

.lh-inter {
  line-height: 1.5; }

@media screen and (min-width: 768px) {
  .lh-3 {
    line-height: 3.4rem; } }

.lh-g {
  line-height: 2.6rem; }
  @media screen and (min-width: 768px) {
    .lh-g {
      line-height: 3rem; } }
  @media screen and (min-width: 1024px) {
    .lh-g {
      line-height: 3.7rem; } }

.lh-m {
  line-height: 1.3; }

@media screen and (min-width: 768px) {
  .lh-4 {
    line-height: 3rem; } }

@media screen and (min-width: 1024px) {
  .lh-4 {
    line-height: 4.5rem; } }

.list-medium {
  font-size: 1.5rem; }
  @media (min-width: 768px) {
    .list-medium {
      font-size: 1.7rem; } }
  @media (min-width: 1024px) {
    .list-medium {
      font-size: 1.98rem; } }

@media (min-width: 1024px) {
  .only-t-fotter {
    font-size: 3.7rem; } }

.ta-c {
  text-align: center; }

.is-serif {
  font-family: "BwDarius-Light", serif; }

.is-sansSerif {
  font-family: "Josefin Sans", sans-serif; }

/*-----------------------------------*\
	HORIZONTAL RULE
\*-----------------------------------*/
hr {
  margin: 3em auto;
  height: 3px;
  background-color: #252525;
  border: none; }
  hr.is-style-wide {
    margin: 6em auto;
    max-width: 1024px; }
  hr.is-style-dots::before {
    font-weight: bold;
    font-size: 35px; }

/*-----------------------------------*\
	Cite
\*-----------------------------------*/
.cite-author {
  font-size: 1.5rem; }
  @media screen and (min-width: 768px) {
    .cite-author {
      font-size: 2rem; } }

/*----------------------------------------------------------------*\
		FORMS
\*----------------------------------------------------------------*/
/*----------------------------------------------------------------*\
		BUTTONS
\*----------------------------------------------------------------*/
button,
.button,
input[type="submit"] {
  cursor: pointer;
  margin: 0.5em 0;
  padding: 0.75em 1em;
  box-shadow: none;
  border: none;
  border-radius: 0;
  position: relative;
  font-size: 20px;
  letter-spacing: 2px;
  color: #fff;
  text-decoration: none;
  font-family: "BwDarius-Light", serif;
  text-transform: uppercase; }
  @media screen and (min-width: 768px) {
    button,
    .button,
    input[type="submit"] {
      font-size: 24px; } }

.button-container {
  width: 100%; }
  @media screen and (min-width: 768px) {
    .button-container {
      width: auto; } }
  .button-container .generic-button {
    background-color: #252525;
    color: #E9D495;
    padding: 18px 18px 13px;
    text-decoration: none;
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: 2px;
    display: block;
    text-align: center;
    line-height: 1.4;
    font-family: "Josefin Sans", sans-serif;
    text-transform: uppercase; }
    @media screen and (min-width: 640px) {
      .button-container .generic-button {
        padding: 19px 35px; } }
    @media screen and (min-width: 768px) {
      .button-container .generic-button {
        padding: 18px 40px 14px;
        font-size: 1.7rem; } }
    .button-container .generic-button.button-big {
      max-width: 100%; }
    @media screen and (min-width: 768px) {
      .button-container .generic-button.button-small {
        max-width: 180px;
        display: inline; } }
    @media screen and (min-width: 1024px) {
      .button-container .generic-button.button-small {
        display: block; } }
    @media screen and (min-width: 768px) {
      .button-container .generic-button.button-long {
        max-width: 278px; } }
    .button-container .generic-button.input-button  {
      color: #E9D495;
      padding: 14px 50px;
      font-size: 16px; }
  .button-container .white-button {
    line-height: 1.6;
    background-color: #fff;
    color: #252525;
    padding: 20px 35px 15px 35px;
    text-decoration: none;
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: 2px;
    display: block;
    max-width: 614px;
    margin: 0 auto;
    text-transform: uppercase;
    font-family: "Josefin Sans", sans-serif; }
    @media screen and (min-width: 768px) {
      .button-container .white-button {
        padding: 20px 15px 15px 15px;
        font-size: 1.7rem; } }

article.challenges-card > div {
  margin: 0; }
  @media screen and (min-width: 768px) {
    article.challenges-card > div {
      margin-top: 2rem;
      margin-bottom: 8rem; } }
  article.challenges-card > div .grid {
    padding-top: 0; }
    @media screen and (min-width: 768px) {
      article.challenges-card > div .grid {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 1fr;
        gap: 0px 0px; } }
    @media screen and (min-width: 1024px) {
      article.challenges-card > div .grid {
        display: grid;
        grid-template-columns: 2fr 2.5fr;
        grid-template-rows: 1fr;
        gap: 0px 0px; } }
    article.challenges-card > div .grid > div:first-child {
      margin-bottom: 3rem; }
      @media screen and (min-width: 768px) {
        article.challenges-card > div .grid > div:first-child {
          margin-bottom: 0;
          padding: 0 2rem 0 0; } }
      article.challenges-card > div .grid > div:first-child figure {
        margin: 5rem 0 0 0;
        position: relative;
        max-height: 30rem;
        overflow: hidden; }
        @media screen and (min-width: 640px) {
          article.challenges-card > div .grid > div:first-child figure {
            max-height: 35rem;
            margin: 0; } }
        @media screen and (min-width: 768px) {
          article.challenges-card > div .grid > div:first-child figure {
            max-height: none; } }
        article.challenges-card > div .grid > div:first-child figure img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center; }
        article.challenges-card > div .grid > div:first-child figure > span {
          background: linear-gradient(90deg, #b38141 13%, #bd9253 24%, #cba768 68%, #e4cf90 100%);
          height: 2rem;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%; }
          @media screen and (min-width: 768px) {
            article.challenges-card > div .grid > div:first-child figure > span {
              height: 3rem; } }
          @media screen and (min-width: 1024px) {
            article.challenges-card > div .grid > div:first-child figure > span {
              height: 4rem; } }
    article.challenges-card > div .grid > div:last-child {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: flex-start; }
      @media screen and (min-width: 768px) {
        article.challenges-card > div .grid > div:last-child {
          justify-content: center; } }
      @media screen and (min-width: 1024px) {
        article.challenges-card > div .grid > div:last-child {
          padding-left: 5.5rem; } }
      article.challenges-card > div .grid > div:last-child h4 {
        margin: 0;
        text-transform: uppercase; }
      article.challenges-card > div .grid > div:last-child p {
        margin-bottom: 3rem; }
        @media screen and (min-width: 1024px) {
          article.challenges-card > div .grid > div:last-child p {
            margin-bottom: 4rem; } }
      @media screen and (min-width: 1024px) {
        article.challenges-card > div .grid > div:last-child a {
          max-width: max-content; } }

section.page-not-found {
  min-height: 50vh;
  display: flex;
  align-items: center; }
  @media screen and (min-width: 1024px) {
    section.page-not-found {
      min-height: 80vh; } }
  section.page-not-found > div {
    text-align: center;
    margin-top: 8rem; }
    section.page-not-found > div h2 {
      font-size: 4rem; }
      @media screen and (min-width: 768px) {
        section.page-not-found > div h2 {
          font-size: 5rem; } }
      @media screen and (min-width: 1024px) {
        section.page-not-found > div h2 {
          font-size: 8rem; } }
    section.page-not-found > div p {
      font-size: 2.5rem; }
      @media screen and (min-width: 768px) {
        section.page-not-found > div p {
          font-size: 3rem; } }
      @media screen and (min-width: 1024px) {
        section.page-not-found > div p {
          font-size: 4rem; } }

/*----------------------------------------------------------------*\
		MAIN NAV
\*----------------------------------------------------------------*/
header {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  position: fixed; }
  @media screen and (min-width: 1024px) {
    header {
      position: absolute;
      background: none; } }
  header .skip-content {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: 10; }
    header .skip-content:focus {
      position: absolute;
      left: 0;
      top: 0;
      width: auto;
      height: auto;
      overflow: hidden; }
  @media screen and (min-width: 768px) {
    header nav.main-nav.home ul li:hover ul, header nav.main-nav.whyus ul li:hover ul {
      background: #252525; } }
  header nav.main-nav.home ul li a, header nav.main-nav.whyus ul li a, header nav.main-nav.executive-search ul li a, header nav.main-nav.research ul li a, header nav.main-nav.talent-management-consulting ul li a, header nav.main-nav.blog ul li a {
    color: #fff; }
    header nav.main-nav.home ul li a.active, header nav.main-nav.whyus ul li a.active, header nav.main-nav.executive-search ul li a.active, header nav.main-nav.research ul li a.active, header nav.main-nav.talent-management-consulting ul li a.active, header nav.main-nav.blog ul li a.active {
      color: #BF9454; }
  header nav.main-nav.home ul li ul li.active a, header nav.main-nav.whyus ul li ul li.active a, header nav.main-nav.executive-search ul li ul li.active a, header nav.main-nav.research ul li ul li.active a, header nav.main-nav.talent-management-consulting ul li ul li.active a, header nav.main-nav.blog ul li ul li.active a {
    border-bottom: 1px solid #fff; }
  header nav.main-nav.home ul li ul li.hvr-underline-from-left-little a::before, header nav.main-nav.whyus ul li ul li.hvr-underline-from-left-little a::before, header nav.main-nav.executive-search ul li ul li.hvr-underline-from-left-little a::before, header nav.main-nav.research ul li ul li.hvr-underline-from-left-little a::before, header nav.main-nav.talent-management-consulting ul li ul li.hvr-underline-from-left-little a::before, header nav.main-nav.blog ul li ul li.hvr-underline-from-left-little a::before {
    background: #fff; }
  header nav.main-nav.services ul li a, header nav.main-nav.challenges ul li a, header nav.main-nav.contact ul li a {
    color: #fff; }
    @media screen and (min-width: 1024px) {
      header nav.main-nav.services ul li a, header nav.main-nav.challenges ul li a, header nav.main-nav.contact ul li a {
        color: #252525; } }
    header nav.main-nav.services ul li a.active, header nav.main-nav.challenges ul li a.active, header nav.main-nav.contact ul li a.active {
      color: #BF9454; }
  @media screen and (min-width: 1024px) {
    header nav.main-nav.services ul li ul li.hvr-underline-from-left-little a::before, header nav.main-nav.challenges ul li ul li.hvr-underline-from-left-little a::before, header nav.main-nav.contact ul li ul li.hvr-underline-from-left-little a::before {
      background: #252525; } }
  header nav.main-nav.blog .logo-burger {
    background-color: #545454 !important; }
  @media screen and (min-width: 1024px) {
    header nav.main-nav.blog {
      background-color: #545454; } }
  header nav.main-nav .nav {
    position: fixed;
    background: #252525;
    top: -100%;
    left: 0;
    right: 0;
    height: 100%;
    padding: 12rem 3rem 5rem 3rem;
    z-index: -1;
    transition: top .5s ease; }
    header nav.main-nav .nav.open {
      top: 0;
      transition: top .5s ease; }
    @media screen and (min-width: 768px) {
      header nav.main-nav .nav {
        padding: 20rem 3rem 5rem 5rem; } }
    @media screen and (min-width: 1024px) {
      header nav.main-nav .nav {
        margin-right: 11rem;
        display: block;
        padding: 0;
        position: static;
        top: auto;
        background: none;
        right: auto;
        left: auto;
        z-index: 1; } }
    header nav.main-nav .nav > ul {
      list-style-type: none;
      display: flex;
      flex-direction: column; }
      @media screen and (min-width: 1024px) {
        header nav.main-nav .nav > ul {
          gap: 2rem;
          list-style-type: none;
          display: flex;
          flex-direction: row;
          align-items: center; } }
      header nav.main-nav .nav > ul > li {
        display: inline-block;
        margin-bottom: 2rem; }
        header nav.main-nav .nav > ul > li .border-m {
          border-bottom: 1px solid #BF9454;
          width: max-content; }
          @media screen and (min-width: 1024px) {
            header nav.main-nav .nav > ul > li .border-m {
              border: none; } }
        @media screen and (min-width: 768px) {
          header nav.main-nav .nav > ul > li {
            position: relative;
            padding-right: 3.8rem;
            transition-duration: 0.5s; } }
        @media screen and (min-width: 1024px) {
          header nav.main-nav .nav > ul > li {
            margin: 0; } }
        header nav.main-nav .nav > ul > li a {
          text-decoration: none;
          line-height: 1.5;
          font-size: 20px; }
          @media screen and (min-width: 768px) {
            header nav.main-nav .nav > ul > li a {
              padding-bottom: 0.7rem; } }
          @media screen and (min-width: 1024px) {
            header nav.main-nav .nav > ul > li a {
              font-size: 16px; } }
        @media screen and (min-width: 768px) {
          header nav.main-nav .nav > ul > li:nth-child(0n+2):hover > a {
            color: #BF9454; } }
        @media screen and (min-width: 768px) {
          header nav.main-nav .nav > ul > li:hover li {
            color: #BF9454; } }
        header nav.main-nav .nav > ul > li ul {
          list-style-type: square;
          padding-left: 2rem; }
          @media screen and (min-width: 1024px) {
            header nav.main-nav .nav > ul > li ul {
              position: absolute;
              width: 170px;
              left: -14px;
              max-height: 0;
              overflow: hidden;
              -webkit-transition: max-height 0.4s linear;
              -moz-transition: max-height 0.4s linear;
              transition: max-height 0.4s linear;
              z-index: 3;
              padding-top: 20px;
              padding-left: 14px;
              padding-right: 14px; } }
          @media screen and (min-width: 1024px) {
            header nav.main-nav .nav > ul > li ul {
              padding-left: 0;
              list-style-type: none;
              z-index: 3; } }
          header nav.main-nav .nav > ul > li ul li {
            margin-bottom: 0.5rem;
            margin-top: 1rem;
            font-size: 2rem;
            color: #BF9454; }
            @media screen and (min-width: 1024px) {
              header nav.main-nav .nav > ul > li ul li {
                margin-top: 0.5rem;
                color: inherit;
                padding-left: 1.4rem;
                padding-bottom: 0.2rem; }
                header nav.main-nav .nav > ul > li ul li a {
                  font-size: 14px; } }
        @media screen and (min-width: 768px) {
          header nav.main-nav .nav > ul > li:hover ul {
            max-height: 220px; } }
  @media screen and (min-width: 1024px) {
    header nav.main-nav .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 2rem;
      padding-right: 0; } }
  header nav.main-nav .flex .logo-burger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2em;
    background: #252525; }
    @media screen and (min-width: 768px) {
      header nav.main-nav .flex .logo-burger {
        padding: 1rem 4.6rem; } }
    @media screen and (min-width: 1024px) {
      header nav.main-nav .flex .logo-burger {
        padding: 3rem 4.2rem;
        background: none; } }
    @media screen and (min-width: 1200px) {
      header nav.main-nav .flex .logo-burger {
        padding: 3rem 10rem; } }
  header nav.main-nav .menu-burger .lines-burger {
    display: flex;
    flex-direction: column;
    width: 4rem;
    cursor: pointer; }
    header nav.main-nav .menu-burger .lines-burger span {
      background: #BF9454;
      height: 2px;
      margin: 5px 0;
      transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6); }
    header nav.main-nav .menu-burger .lines-burger span:nth-of-type(1) {
      width: 46%; }
    header nav.main-nav .menu-burger .lines-burger span:nth-of-type(2) {
      width: 94%; }
    header nav.main-nav .menu-burger .lines-burger span:nth-of-type(3) {
      width: 56%; }
    header nav.main-nav .menu-burger .lines-burger input[type="checkbox"] {
      display: none; }
    header nav.main-nav .menu-burger .lines-burger input[type="checkbox"]:checked ~ span:nth-of-type(1) {
      width: 47%;
      transform-origin: bottom;
      transform: rotatez(45deg) translate(5px, 1px); }
    header nav.main-nav .menu-burger .lines-burger input[type="checkbox"]:checked ~ span:nth-of-type(2) {
      transform-origin: top;
      transform: rotatez(-45deg); }
    header nav.main-nav .menu-burger .lines-burger input[type="checkbox"]:checked ~ span:nth-of-type(3) {
      transform-origin: bottom;
      width: 47%;
      transform: translate(17px, -6px) rotatez(45deg); }

/*----------------------------------------------------------------*\
		MAIN FOOTER
\*----------------------------------------------------------------*/
footer.main-footer {
  background-color: #252525;
  padding-top: 4rem;
  padding-bottom: 4rem;
  position: relative; }
  @media screen and (min-width: 768px) {
    footer.main-footer {
      padding: 5rem 3rem; } }
  @media screen and (min-width: 1200px) {
    footer.main-footer {
      padding-top: 7rem;
      padding-bottom: 3rem; } }
  footer.main-footer .grid {
    text-align: center;
    max-width: 1600px;
    margin: 0 auto;
    padding: 2rem; }
    @media screen and (min-width: 1200px) {
      footer.main-footer .grid {
        display: grid;
        grid-template-columns: 2fr 6fr;
        gap: 10rem; } }
    footer.main-footer .grid .main-footer-logo {
      display: none; }
      @media screen and (min-width: 1200px) {
        footer.main-footer .grid .main-footer-logo {
          display: block; } }
      footer.main-footer .grid .main-footer-logo img {
        height: 90%;
        width: auto;
        filter: invert(27%); }
    footer.main-footer .grid .main-footer-content {
      padding: 2rem;
      text-align: left; }
      @media screen and (min-width: 1200px) {
        footer.main-footer .grid .main-footer-content {
          padding: 0; } }
      footer.main-footer .grid .main-footer-content h4 {
        margin-top: 0;
        margin-bottom: 0; }
      footer.main-footer .grid .main-footer-content p {
        color: #fff;
        text-align: left; }
        @media screen and (min-width: 768px) {
          footer.main-footer .grid .main-footer-content p {
            padding: 0; } }
        @media screen and (min-width: 1200px) {
          footer.main-footer .grid .main-footer-content p {
            margin: 0; } }
      footer.main-footer .grid .main-footer-content ul {
        list-style-type: none;
        display: flex;
        align-items: center;
        flex-wrap: wrap; }
        footer.main-footer .grid .main-footer-content ul li {
          color: #858585;
          display: inline-flex;
          width: 40%;
          padding: 1.4rem 0; }
          @media screen and (min-width: 768px) {
            footer.main-footer .grid .main-footer-content ul li {
              display: inline-block;
              width: auto;
              padding: 2.5rem 0; } }
          @media screen and (min-width: 1200px) {
            footer.main-footer .grid .main-footer-content ul li {
              display: inline-block;
              padding-right: 0;
              margin-bottom: 3.25rem;
              margin-top: 1.2rem; } }
      footer.main-footer .grid .main-footer-content a {
        text-decoration: none;
        text-transform: uppercase; }
      footer.main-footer .grid .main-footer-content > div {
        line-height: 0; }
        @media screen and (min-width: 768px) {
          footer.main-footer .grid .main-footer-content > div:nth-child(2) {
            padding: 2rem 0 3rem 0; } }
        @media screen and (min-width: 1200px) {
          footer.main-footer .grid .main-footer-content > div:nth-child(2) {
            padding: 0;
            margin: 4rem 0 0rem 0;
            padding: 0; } }
        footer.main-footer .grid .main-footer-content > div:nth-child(2) a {
          padding-right: 2rem; }
      footer.main-footer .grid .main-footer-content .logos {
        margin-bottom: 4rem; }
        @media screen and (min-width: 1200px) {
          footer.main-footer .grid .main-footer-content .logos {
            margin-bottom: 4rem; } }
        @media screen and (min-width: 1200px) {
          footer.main-footer .grid .main-footer-content .logos > div {
            display: flex;
            align-items: center; }
            footer.main-footer .grid .main-footer-content .logos > div .social a img {
              height: 3rem;
              width: auto; } }
        footer.main-footer .grid .main-footer-content .logos > div .certifications {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-top: 3rem; }
          @media screen and (min-width: 768px) {
            footer.main-footer .grid .main-footer-content .logos > div .certifications {
              margin-top: 5rem; } }
          @media screen and (min-width: 1200px) {
            footer.main-footer .grid .main-footer-content .logos > div .certifications {
              margin-top: 0;
              margin-left: 3rem; } }
          footer.main-footer .grid .main-footer-content .logos > div .certifications img {
            max-width: 40%;
            height: auto; }
            footer.main-footer .grid .main-footer-content .logos > div .certifications img:last-child {
              margin-left: 2rem; }
            @media screen and (min-width: 768px) {
              footer.main-footer .grid .main-footer-content .logos > div .certifications img {
                max-width: 16rem; } }
            @media screen and (min-width: 1200px) {
              footer.main-footer .grid .main-footer-content .logos > div .certifications img {
                max-width: 18rem; } }
      footer.main-footer .grid .main-footer-content .credits {
        color: #858585;
        display: inline;
        font-size: 1.2rem;
        line-height: 2rem; }
        footer.main-footer .grid .main-footer-content .credits a {
          color: #858585; }

section.why-us-hero {
  background: #252525;
  position: relative;
  padding-top: 87px; }
  @media screen and (min-width: 768px) {
    section.why-us-hero {
      padding-top: 0; } }
  section.why-us-hero::before {
    content: "";
    position: absolute;
    background: url(/assets/img/hero-stairs-bg.png) no-repeat top left;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-size: 372px; }
    @media screen and (min-width: 640px) {
      section.why-us-hero::before {
        background-size: 690px; } }
    @media screen and (min-width: 768px) {
      section.why-us-hero::before {
        background-size: 547px;
        margin-top: 0; } }
    @media screen and (min-width: 1024px) {
      section.why-us-hero::before {
        background-size: 700px; } }
  section.why-us-hero .grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas: ". text text ." ". text text ."; }
    @media screen and (min-width: 768px) {
      section.why-us-hero .grid {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 0.7fr 1.3fr;
        grid-template-areas: ". . nav nav nav nav" ". . . text text text"; } }
    section.why-us-hero .grid p {
      grid-area: text;
      padding-bottom: 3rem;
      color: #fff;
      position: relative; }
      @media screen and (min-width: 768px) {
        section.why-us-hero .grid p {
          margin-right: 1rem; } }
      @media screen and (min-width: 1024px) {
        section.why-us-hero .grid p {
          border-bottom: 16px solid transparent;
          margin-right: 5rem;
          margin-bottom: 11rem; } }
      section.why-us-hero .grid p span {
        background: linear-gradient(90deg, #b38141 13%, #bd9253 24%, #cba768 68%, #e4cf90 100%);
        height: 1rem;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%; }
        @media screen and (min-width: 1024px) {
          section.why-us-hero .grid p span {
            height: 1.7rem; } }

section.organization {
  overflow: hidden; }
  section.organization > div {
    position: relative; }
    @media screen and (min-width: 768px) {
      section.organization > div {
        padding-bottom: 4rem;
        padding-top: 0rem; } }
    @media screen and (min-width: 768px) {
      section.organization > div .line {
        position: absolute;
        width: 18px;
        border: 1px solid #BF9454;
        transform: translateX(-50%); } }
    @media screen and (min-width: 768px) {
      section.organization > div .line-1 {
        top: 1rem;
        right: 3%;
        height: 714px;
        transform: rotate(46deg); } }
    @media screen and (min-width: 768px) {
      section.organization > div .line-2 {
        top: 7rem;
        right: 4%;
        height: 450px;
        transform: rotate(-1deg); } }
    @media screen and (min-width: 768px) {
      section.organization > div .line-3 {
        top: 7rem;
        right: 4%;
        height: 450px;
        transform: rotate(-24deg); } }
  @media screen and (min-width: 768px) {
    section.organization .grid {
      display: grid;
      grid-template-columns: 0.4fr 1fr 1.7fr 1fr 1fr 1fr;
      gap: 0px 0px;
      grid-template-areas: ". p1 p1 p1 p1 ." ". p2 p2 . . ."; } }
  @media screen and (min-width: 1024px) {
    section.organization .grid {
      grid-template-rows: 1fr; } }
  @media screen and (min-width: 1200px) {
    section.organization .grid {
      grid-template-rows: 1fr 1fr; } }
  section.organization .grid > div:first-child {
    grid-area: p1; }
    @media screen and (min-width: 768px) {
      section.organization .grid > div:first-child p {
        margin: 0;
        padding-top: 12rem;
        padding-bottom: 10rem; } }
    @media screen and (min-width: 1024px) {
      section.organization .grid > div:first-child p {
        padding-top: 12.7rem;
        padding-bottom: 7.1rem; } }
  section.organization .grid > div:last-child {
    grid-area: p2; }
    section.organization .grid > div:last-child h4 {
      color: #BF9454;
      margin-top: 5rem;
      text-transform: uppercase; }
      @media screen and (min-width: 1024px) {
        section.organization .grid > div:last-child h4 {
          line-height: 3.7rem; } }
    @media screen and (min-width: 1024px) {
      section.organization .grid > div:last-child p {
        padding-right: 1em; } }

section.leadership {
  background: #252525;
  position: relative; }
  @media screen and (min-width: 768px) {
    section.leadership {
      margin: 0px 3rem 15rem; } }
  @media screen and (min-width: 1024px) {
    section.leadership {
      margin: 0px 12rem 11rem; } }
  @media screen and (min-width: 1200px) {
    section.leadership {
      margin: 0px 11rem 15rem; } }
  section.leadership > div {
    position: relative;
    text-align: center; }
    @media screen and (min-width: 768px) {
      section.leadership > div {
        z-index: 2;
        padding: 7rem 0rem; } }
    @media screen and (min-width: 1024px) {
      section.leadership > div {
        padding: 6rem 3rem; } }
    @media screen and (min-width: 1200px) {
      section.leadership > div {
        padding: 5rem 20rem; } }
    section.leadership > div p {
      margin-top: 5rem; }
      @media screen and (min-width: 768px) {
        section.leadership > div p {
          margin-top: 4rem; } }
      @media screen and (min-width: 1200px) {
        section.leadership > div p {
          font-size: 18px; } }
    section.leadership > div h4 {
      color: #BF9454;
      text-transform: uppercase; }
    section.leadership > div .button-container {
      margin-top: 4rem; }
      @media screen and (min-width: 768px) {
        section.leadership > div .button-container {
          margin-top: 6rem; } }
      @media screen and (min-width: 1024px) {
        section.leadership > div .button-container {
          margin-top: 5rem; } }
      @media screen and (min-width: 1200px) {
        section.leadership > div .button-container {
          margin-top: 4rem; } }

section.advantages {
  background: #F7F5E9;
  padding-top: 2rem; }
  @media screen and (min-width: 768px) {
    section.advantages {
      padding-top: 3rem; } }
  @media screen and (min-width: 1200px) {
    section.advantages {
      padding-top: 13rem; } }
  @media screen and (min-width: 768px) {
    section.advantages .grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 0px 0px;
      grid-template-areas: "area1 area2" ". area2"; } }
  @media screen and (min-width: 1024px) {
    section.advantages .grid {
      grid-template-columns: 1fr 1fr;
      padding-bottom: 10rem; } }
  @media screen and (min-width: 768px) {
    section.advantages .grid > div:first-child {
      grid-area: area1; } }
  @media screen and (min-width: 768px) {
    section.advantages .grid > div:first-child {
      padding: 0 3rem; } }
  @media screen and (min-width: 1200px) {
    section.advantages .grid > div:first-child {
      padding: 0 7rem; } }
  section.advantages .grid > div:first-child > h3 {
    text-transform: uppercase; }
  section.advantages .grid > div:first-child > h3:first-child {
    border-bottom: 2px solid #BF9454; }
  section.advantages .grid > div:first-child > h3:last-child {
    padding-top: 1rem;
    margin: 0; }
  section.advantages .grid > div:first-child h3 {
    margin-right: 6rem;
    padding-bottom: 1rem; }
    @media screen and (min-width: 400px) {
      section.advantages .grid > div:first-child h3 {
        margin-right: 24rem; } }
    @media screen and (min-width: 640px) {
      section.advantages .grid > div:first-child h3 {
        margin-right: 31rem; } }
    @media screen and (min-width: 768px) {
      section.advantages .grid > div:first-child h3 {
        margin-right: 0; } }
    @media screen and (min-width: 1024px) {
      section.advantages .grid > div:first-child h3 {
        margin-right: 3rem;
        line-height: 55px; } }
  section.advantages .grid > div:last-child {
    grid-area: area2;
    padding-top: 2rem; }
    @media screen and (min-width: 768px) {
      section.advantages .grid > div:last-child {
        padding: 0 2rem; } }
    @media screen and (min-width: 1024px) {
      section.advantages .grid > div:last-child {
        padding: 0px 5.5rem; } }
    section.advantages .grid > div:last-child ul {
      list-style-type: none; }
      section.advantages .grid > div:last-child ul h4 {
        color: #BF9454;
        text-transform: uppercase; }
      section.advantages .grid > div:last-child ul p {
        margin-top: 5px; }
      section.advantages .grid > div:last-child ul li {
        padding-bottom: 2rem; }

@media screen and (min-width: 768px) {
  section.discover {
    padding: 5rem 4rem; } }

@media screen and (min-width: 1200px) {
  section.discover {
    padding: 0; } }

section.discover > div {
  text-align: center;
  line-height: 1.5; }
  @media screen and (min-width: 1024px) {
    section.discover > div {
      padding: 3rem 4rem; } }
  @media screen and (min-width: 1200px) {
    section.discover > div {
      padding: 12rem 27rem;
      line-height: 1.5; } }
  section.discover > div a {
    text-transform: uppercase; }

section.services-let-us-serve > div {
  padding-top: 15rem; }
  @media screen and (min-width: 1024px) {
    section.services-let-us-serve > div {
      padding-top: 24rem;
      padding-bottom: 12rem; } }
  @media screen and (min-width: 768px) {
    section.services-let-us-serve > div .grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 0px 0px;
      grid-template-areas: "animation text text"; } }
  @media screen and (min-width: 1024px) {
    section.services-let-us-serve > div .grid {
      grid-template-columns: 1.1fr 1.2fr 0.1fr 1.4fr 1.1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas: "animation animation . text text" "animation animation . text text" "animation animation . text text"; } }
  section.services-let-us-serve > div .grid > div:first-child {
    grid-area: animation;
    position: relative; }
  section.services-let-us-serve > div .grid > div:last-child {
    grid-area: text; }
    @media screen and (min-width: 1024px) {
      section.services-let-us-serve > div .grid > div:last-child {
        padding-right: 3.5rem; } }
    section.services-let-us-serve > div .grid > div:last-child > div:first-child h4 {
      padding-bottom: 3.5rem;
      margin: 0; }
    section.services-let-us-serve > div .grid > div:last-child > div:first-child p {
      padding-bottom: 4rem;
      margin: 0; }
    section.services-let-us-serve > div .grid > div:last-child > div:last-child h4 {
      color: #BF9454;
      margin: 0;
      padding-top: 4rem;
      padding-bottom: 1.7rem;
      text-transform: uppercase; }
    section.services-let-us-serve > div .grid > div:last-child > div:last-child p {
      margin: 0;
      padding-bottom: 3rem; }
    section.services-let-us-serve > div .grid > div:last-child > span {
      display: inline-block;
      width: 100%;
      height: 1.8rem;
      background: linear-gradient(90deg, #b38141 13%, #bd9253 24%, #cba768 68%, #e4cf90 100%); }
  @media screen and (min-width: 768px) {
    section.services-let-us-serve > div .grid .line {
      position: absolute;
      width: 18px;
      border: 1px solid #BF9454;
      transform: translateX(-50%); } }
  @media screen and (min-width: 768px) {
    section.services-let-us-serve > div .grid .line-1 {
      top: -1%;
      left: -38%;
      height: 450px;
      transform: rotate(-22deg); } }
  @media screen and (min-width: 1024px) {
    section.services-let-us-serve > div .grid .line-1 {
      top: -5%;
      left: 33%;
      height: 747px; } }
  @media screen and (min-width: 768px) {
    section.services-let-us-serve > div .grid .line-2 {
      top: -3%;
      left: 1%;
      height: 421px;
      transform: rotate(48deg); } }
  @media screen and (min-width: 1024px) {
    section.services-let-us-serve > div .grid .line-2 {
      top: -9%;
      left: 13%;
      height: 635px;
      transform: rotate(48deg); } }
  @media screen and (min-width: 768px) {
    section.services-let-us-serve > div .grid .line-3 {
      top: 3%;
      left: 18%;
      height: 320px;
      transform: rotate(77deg); } }
  @media screen and (min-width: 1024px) {
    section.services-let-us-serve > div .grid .line-3 {
      height: 652px; } }

section.our-process {
  color: #fff;
  background: #545454;
  background-image: url(/assets/img/building.png);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: contain;
  padding-bottom: 26rem; }
  @media screen and (min-width: 640px) {
    section.our-process {
      padding-bottom: 35rem; } }
  @media screen and (min-width: 768px) {
    section.our-process {
      padding-bottom: 45rem; } }
  @media screen and (min-width: 1024px) {
    section.our-process {
      padding-bottom: 60rem; } }
  @media screen and (min-width: 1200px) {
    section.our-process {
      padding-bottom: 75rem; } }
  @media screen and (min-width: 1450px) {
    section.our-process {
      padding-bottom: 90rem; } }
  @media screen and (min-width: 1590px) {
    section.our-process {
      padding-bottom: 108rem; } }
  section.our-process > div {
    padding: 5rem 0rem 0 3rem; }
    @media screen and (min-width: 768px) {
      section.our-process > div {
        padding: 10rem 6rem 4rem; } }
    @media screen and (min-width: 1024px) {
      section.our-process > div {
        padding-top: 10rem; } }
    @media screen and (min-width: 1024px) {
      section.our-process > div .grid {
        display: grid;
        gap: 0px 0px;
        grid-template-columns: 1.1fr 1.2fr 0.1fr 1.4fr 1.1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-areas: "area1 area1 . area2 area2" "area1 area1 . area2 area2" "area1 area1 . area2 area2"; } }
    @media screen and (min-width: 1024px) {
      section.our-process > div .grid > div:first-child {
        grid-area: area1; } }
    @media screen and (min-width: 1200px) {
      section.our-process > div .grid > div:first-child {
        padding-left: 7rem; } }
    section.our-process > div .grid > div:first-child h3 {
      text-transform: uppercase; }
      @media screen and (min-width: 768px) {
        section.our-process > div .grid > div:first-child h3 {
          font-size: 38px; } }
    @media screen and (min-width: 1024px) {
      section.our-process > div .grid > div:last-child {
        grid-area: area2; } }
    section.our-process > div .grid > div:last-child .description {
      padding-bottom: 3rem; }
      @media (min-width: 768px) {
        section.our-process > div .grid > div:last-child .description {
          padding-bottom: 6rem; } }
    section.our-process > div .grid > div:last-child ol {
      counter-reset: item;
      padding: 0px 0px 0rem 5rem;
      list-style-type: square;
      padding: 0 2rem;
      margin-bottom: 4rem;
      font-size: 1.5rem; }
      @media (min-width: 768px) {
        section.our-process > div .grid > div:last-child ol {
          font-size: 1.7rem; } }
      @media screen and (min-width: 1024px) {
        section.our-process > div .grid > div:last-child ol {
          font-size: 1.98rem;
          margin-bottom: 6rem;
          padding: 0 0rem 0 2.5rem; } }
      section.our-process > div .grid > div:last-child ol ::marker {
        color: #E9D495;
        font-size: x-large; }
      @media screen and (min-width: 768px) {
        section.our-process > div .grid > div:last-child ol {
          padding: 0; } }
      section.our-process > div .grid > div:last-child ol > li {
        display: block;
        position: relative; }
        section.our-process > div .grid > div:last-child ol > li::before {
          content: counter(item) "  ";
          counter-increment: item;
          position: absolute;
          top: 0;
          left: -5rem;
          font-size: 6rem;
          font-weight: bold; }
        section.our-process > div .grid > div:last-child ol > li h4 {
          padding-bottom: 1.3rem;
          text-transform: uppercase; }
        section.our-process > div .grid > div:last-child ol > li p {
          margin-bottom: 1rem;
          font-size: 1.5rem; }
          @media screen and (min-width: 768px) {
            section.our-process > div .grid > div:last-child ol > li p {
              margin-bottom: 7rem;
              font-size: 1.7rem;
              padding-bottom: 1rem;
              margin: 8px 0px; } }
          @media (min-width: 1024px) {
            section.our-process > div .grid > div:last-child ol > li p {
              font-size: 1.98rem; } }
        section.our-process > div .grid > div:last-child ol > li ul {
          list-style-type: square;
          padding-bottom: 2rem; }
          @media screen and (min-width: 768px) {
            section.our-process > div .grid > div:last-child ol > li ul {
              padding-bottom: 6rem;
              padding-left: 2rem; } }
          section.our-process > div .grid > div:last-child ol > li ul ::marker {
            color: #E9D495;
            font-size: x-large; }
          section.our-process > div .grid > div:last-child ol > li ul li {
            line-height: 1.5;
            padding: 1rem 0;
            font-family: "BwDarius-Light", serif; }
            @media screen and (min-width: 1024px) {
              section.our-process > div .grid > div:last-child ol > li ul li {
                padding: 1rem 0  0; } }

section.what-can-we-do {
  overflow: hidden; }
  @media screen and (min-width: 768px) {
    section.what-can-we-do {
      padding-bottom: 6rem; } }
  @media screen and (min-width: 1024px) {
    section.what-can-we-do {
      padding-bottom: 2rem; } }
  section.what-can-we-do > div {
    position: relative; }
    @media screen and (min-width: 768px) {
      section.what-can-we-do > div .line {
        width: 18px;
        position: absolute;
        border: 1px solid #BF9454;
        transform: translateX(-50%); } }
    @media screen and (min-width: 768px) {
      section.what-can-we-do > div .line-1 {
        top: 17rem;
        left: 4%;
        height: 701px;
        transform: rotate(-156deg); } }
    @media screen and (min-width: 768px) {
      section.what-can-we-do > div .line-2 {
        top: 4rem;
        left: -11%;
        height: 635px;
        transform: rotate(48deg); } }
    @media screen and (min-width: 1024px) {
      section.what-can-we-do > div .line-2 {
        top: 1rem; } }
    @media screen and (min-width: 768px) {
      section.what-can-we-do > div .line-3 {
        top: 24rem;
        left: 18%;
        height: 648px;
        transform: rotate(77deg); } }
    @media screen and (min-width: 768px) {
      section.what-can-we-do > div .line-4 {
        top: 31rem;
        left: 1%;
        height: 485px;
        transform: rotate(31deg); } }
    @media screen and (min-width: 768px) {
      section.what-can-we-do > div {
        padding: 4rem 0; } }
    @media screen and (min-width: 1024px) {
      section.what-can-we-do > div {
        margin-bottom: 12rem;
        padding: 2rem 6rem; } }
    section.what-can-we-do > div > h3 {
      color: #BF9454;
      text-align: left;
      margin-top: 0;
      text-align: center;
      margin-bottom: 2rem; }
      @media screen and (min-width: 768px) {
        section.what-can-we-do > div > h3 {
          margin-bottom: 3rem; } }
      @media screen and (min-width: 1024px) {
        section.what-can-we-do > div > h3 {
          margin-top: 8rem;
          margin-bottom: 5rem; } }
    @media screen and (min-width: 768px) {
      section.what-can-we-do > div .grid {
        display: grid;
        grid-template-columns: 1.4fr 1fr;
        gap: 1rem; } }
    @media screen and (min-width: 1024px) {
      section.what-can-we-do > div .grid {
        gap: 2rem; } }
    @media screen and (min-width: 1200px) {
      section.what-can-we-do > div .grid {
        gap: 8rem; } }
    section.what-can-we-do > div .grid > div:first-child {
      z-index: 2;
      position: relative; }
      @media screen and (min-width: 768px) {
        section.what-can-we-do > div .grid > div:first-child {
          padding: 0 2rem; } }
      @media screen and (min-width: 1024px) {
        section.what-can-we-do > div .grid > div:first-child {
          padding: 0px 1rem; } }
      @media screen and (min-width: 1200px) {
        section.what-can-we-do > div .grid > div:first-child {
          padding: 0 0 0 7rem; } }
      section.what-can-we-do > div .grid > div:first-child img {
        display: none; }
        @media screen and (min-width: 768px) {
          section.what-can-we-do > div .grid > div:first-child img {
            margin-bottom: 0;
            margin-top: 0;
            display: block;
            padding: 0;
            height: 100%;
            width: 95%;
            object-fit: cover;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            transition: all 200ms ease; }
            section.what-can-we-do > div .grid > div:first-child img.active {
              opacity: 1;
              transition: all 200ms ease; } }
        @media screen and (min-width: 1024px) {
          section.what-can-we-do > div .grid > div:first-child img {
            right: 0;
            width: auto;
            object-fit: none;
            left: auto; } }
    section.what-can-we-do > div .grid > div:last-child {
      text-align: center; }
      @media screen and (min-width: 1024px) {
        section.what-can-we-do > div .grid > div:last-child {
          padding: 0; } }
      @media screen and (min-width: 1200px) {
        section.what-can-we-do > div .grid > div:last-child {
          padding: 0 4rem 0 0;
          text-align: left; } }
      section.what-can-we-do > div .grid > div:last-child ul {
        list-style-type: none; }
        @media screen and (min-width: 768px) {
          section.what-can-we-do > div .grid > div:last-child ul {
            text-align: left;
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: center; } }
        section.what-can-we-do > div .grid > div:last-child ul li {
          padding-bottom: 2rem; }
          @media screen and (min-width: 768px) {
            section.what-can-we-do > div .grid > div:last-child ul li {
              padding: 0; }
              section.what-can-we-do > div .grid > div:last-child ul li:nth-child(2) {
                margin: 3rem 0; } }
          section.what-can-we-do > div .grid > div:last-child ul li a {
            line-height: 1.4;
            text-transform: uppercase; }
          section.what-can-we-do > div .grid > div:last-child ul li p {
            margin-top: 0.5rem; }
            @media screen and (min-width: 768px) {
              section.what-can-we-do > div .grid > div:last-child ul li p {
                margin: 0;
                margin-top: 1rem; } }

section.hero-service-detail {
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
  background-color: #252525;
  position: relative;
  padding-top: 87px;
  color: #fff; }
  @media screen and (min-width: 768px) {
    section.hero-service-detail {
      margin-top: 0;
      padding-top: 0; } }
  @media screen and (min-width: 1024px) {
    section.hero-service-detail {
      background-size: auto 100%; } }
  @media screen and (min-width: 768px) {
    section.hero-service-detail .grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2.8fr;
      grid-template-rows: 0.7fr 1.3fr;
      gap: 0px 0px;
      grid-template-areas: "bg bg bg bg bg ." "text text text text text ."; } }
  @media screen and (min-width: 1024px) {
    section.hero-service-detail .grid {
      grid-template-columns: 0.6fr 1.4fr 1fr 1fr 1.4fr 0.6fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas: ". . . bg bg ." ". text text bg bg ."; } }
  @media screen and (min-width: 768px) {
    section.hero-service-detail .grid > div {
      grid-area: text; } }
  section.hero-service-detail .grid > div h4 {
    margin: 0; }
  section.hero-service-detail .grid > div p {
    margin: 0;
    padding-top: 4rem;
    position: relative; }
    @media screen and (min-width: 768px) {
      section.hero-service-detail .grid > div p {
        margin-bottom: 8rem; } }
    section.hero-service-detail .grid > div p:before {
      content: "";
      position: absolute;
      background: linear-gradient(90deg, #b38141 13%, #bd9253 24%, #cba768 68%, #e4cf90 100%);
      padding-bottom: 0.2rem;
      width: 92%;
      margin-top: -2rem; }

section.our-expertise-service-detail {
  background-color: #F7F5E9;
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: contain;
  padding-bottom: 30rem; }
  @media screen and (min-width: 768px) {
    section.our-expertise-service-detail {
      padding-bottom: 0;
      background-size: 51%; } }
  @media screen and (min-width: 1024px) {
    section.our-expertise-service-detail {
      background-size: 728px;
      padding-top: 6rem;
      padding-bottom: 0; } }
  @media screen and (min-width: 1200px) {
    section.our-expertise-service-detail {
      background-size: 842px;
      padding-top: 8rem; } }
  @media screen and (min-width: 768px) {
    section.our-expertise-service-detail .grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 0px 0px;
      grid-template-areas: "area1 area2" ". area2"; } }
  @media screen and (min-width: 1024px) {
    section.our-expertise-service-detail .grid {
      grid-template-columns: 1fr 1fr;
      padding-bottom: 1rem; } }
  @media screen and (min-width: 768px) {
    section.our-expertise-service-detail .grid > div:first-child {
      grid-area: area1; } }
  @media screen and (min-width: 768px) {
    section.our-expertise-service-detail .grid > div:first-child {
      padding: 0 3rem; } }
  @media screen and (min-width: 1200px) {
    section.our-expertise-service-detail .grid > div:first-child {
      padding: 0 5rem 0 11rem; } }
  section.our-expertise-service-detail .grid > div:first-child > h3:last-child {
    padding-top: 1rem;
    margin: 0; }
  section.our-expertise-service-detail .grid > div:first-child h3 {
    margin-right: 6rem;
    padding-bottom: 1rem;
    text-transform: uppercase; }
    @media screen and (min-width: 400px) {
      section.our-expertise-service-detail .grid > div:first-child h3 {
        margin-right: 24rem; } }
    @media screen and (min-width: 640px) {
      section.our-expertise-service-detail .grid > div:first-child h3 {
        margin-right: 31rem; } }
    @media screen and (min-width: 768px) {
      section.our-expertise-service-detail .grid > div:first-child h3 {
        margin-right: 0; } }
    @media screen and (min-width: 1024px) {
      section.our-expertise-service-detail .grid > div:first-child h3 {
        margin-right: 3rem;
        line-height: 55px; } }
  section.our-expertise-service-detail .grid > div:last-child {
    grid-area: area2;
    padding-top: 2rem; }
    @media screen and (min-width: 768px) {
      section.our-expertise-service-detail .grid > div:last-child {
        padding: 0 2rem; } }
    @media screen and (min-width: 1024px) {
      section.our-expertise-service-detail .grid > div:last-child {
        padding: 0px 5.5rem; } }
    section.our-expertise-service-detail .grid > div:last-child ul {
      list-style-type: none; }
      section.our-expertise-service-detail .grid > div:last-child ul h4 {
        color: #BF9454;
        text-transform: uppercase; }
      section.our-expertise-service-detail .grid > div:last-child ul p {
        margin-top: 5px; }
      section.our-expertise-service-detail .grid > div:last-child ul li {
        padding-bottom: 2rem; }

section.we-draw-service-detail {
  overflow: hidden; }
  section.we-draw-service-detail > div {
    position: relative; }
    @media screen and (min-width: 768px) {
      section.we-draw-service-detail > div {
        padding-bottom: 4rem;
        padding-top: 0rem; } }
    @media screen and (min-width: 768px) {
      section.we-draw-service-detail > div .line {
        position: absolute;
        width: 18px;
        border: 1px solid #BF9454;
        transform: translateX(-50%); } }
    @media screen and (min-width: 768px) {
      section.we-draw-service-detail > div .line-1 {
        top: -8rem;
        right: 5%;
        height: 527px;
        transform: rotate(46deg); } }
    @media screen and (min-width: 1024px) {
      section.we-draw-service-detail > div .line-1 {
        top: -39rem;
        right: 5%;
        height: 995px;
        transform: rotate(54deg); } }
    @media screen and (min-width: 768px) {
      section.we-draw-service-detail > div .line-2 {
        top: 9rem;
        right: -11%;
        height: 638px;
        transform: rotate(-66deg); } }
    @media screen and (min-width: 1024px) {
      section.we-draw-service-detail > div .line-2 {
        top: -33rem;
        right: -11%;
        height: 1175px; } }
    @media screen and (min-width: 1200px) {
      section.we-draw-service-detail > div .line-2 {
        top: -21rem;
        height: 1500px; } }
    @media screen and (min-width: 768px) {
      section.we-draw-service-detail > div .line-3 {
        top: 18rem;
        right: 7%;
        height: 433px;
        transform: rotate(-36deg); } }
    @media screen and (min-width: 1024px) {
      section.we-draw-service-detail > div .line-3 {
        top: -14rem;
        right: -11%;
        height: 1300px;
        transform: rotate(-53deg); } }
  @media screen and (min-width: 768px) {
    section.we-draw-service-detail .grid {
      display: grid;
      grid-template-columns: auto 3fr 1.7fr 1fr 1fr 1fr;
      gap: 0px 0px;
      grid-template-areas: ". p1 p1 p1 p1 ." ". p2 p2 . . ."; } }
  @media screen and (min-width: 1024px) {
    section.we-draw-service-detail .grid {
      grid-template-columns: 0.2fr 0.8fr 0.1fr 1fr 0.1fr; } }
  section.we-draw-service-detail .grid > div:first-child {
    grid-area: p2; }
    @media screen and (min-width: 768px) {
      section.we-draw-service-detail .grid > div:first-child p {
        margin: 0;
        padding-top: 12rem;
        padding-bottom: 10rem; } }
    @media screen and (min-width: 1024px) {
      section.we-draw-service-detail .grid > div:first-child p {
        padding-top: 8rem;
        padding-bottom: 5rem; } }

section.your-company-service-detail > div {
  text-align: center;
  line-height: 1.5; }
  @media screen and (min-width: 768px) {
    section.your-company-service-detail > div {
      padding: 7rem 13rem; } }
  @media screen and (min-width: 1024px) {
    section.your-company-service-detail > div {
      padding: 8rem 13rem; } }
  @media screen and (min-width: 1200px) {
    section.your-company-service-detail > div {
      padding: 12rem 27rem;
      line-height: 1.5; } }
  section.your-company-service-detail > div a {
    text-transform: uppercase; }

section.hero {
  position: relative;
  padding-top: 87px; }
  @media screen and (min-width: 1024px) {
    section.hero {
      padding-top: 144px; } }
  section.hero::before {
    content: "";
    position: absolute;
    background: #252525;
    top: 0;
    left: 0;
    right: 0;
    height: 47%;
    z-index: 0; }
    @media screen and (min-width: 768px) {
      section.hero::before {
        height: 47%; } }
    @media screen and (min-width: 1200px) {
      section.hero::before {
        height: 53%; } }
  @media screen and (min-width: 1024px) {
    section.hero .e-avatar {
      padding: 0;
      position: absolute;
      background: url(/assets/img/ethos-outline.svg) no-repeat -42px -7px;
      top: 28%;
      left: 0;
      right: 0;
      height: 64%;
      z-index: -2;
      padding-top: 9rem;
      background-size: 43rem; } }
  @media screen and (min-width: 1200px) {
    section.hero .e-avatar {
      top: 34%; } }
  section.hero .hero-text {
    margin-bottom: 3rem;
    grid-area: text; }
    @media screen and (min-width: 768px) {
      section.hero .hero-text {
        margin-top: 1rem; } }
    @media screen and (min-width: 1024px) {
      section.hero .hero-text {
        margin-top: 5rem;
        padding-right: 6rem; } }
    @media screen and (min-width: 1200px) {
      section.hero .hero-text {
        margin-top: 14rem;
        padding-right: 7rem; } }
  @media screen and (min-width: 1024px) {
    section.hero .hero-description {
      font-size: 1.8rem;
      font-family: "BwDarius-Light", serif;
      line-height: 1.7rem; } }
  section.hero .hero-gradient {
    grid-area: image; }
    @media screen and (min-width: 1024px) {
      section.hero .hero-gradient {
        padding: 0 2rem; } }
  section.hero .hero-image > div {
    position: relative; }
    section.hero .hero-image > div span {
      background: linear-gradient(90deg, #b38141 13%, #bd9253 24%, #cba768 68%, #e4cf90 100%);
      height: 2rem;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%; }
      @media screen and (min-width: 768px) {
        section.hero .hero-image > div span {
          height: 3rem; } }
      @media screen and (min-width: 1024px) {
        section.hero .hero-image > div span {
          height: 4rem; } }
    section.hero .hero-image > div picture {
      height: 200px;
      overflow: hidden;
      display: block; }
      @media screen and (min-width: 768px) {
        section.hero .hero-image > div picture {
          height: 260px; } }
      @media screen and (min-width: 1024px) {
        section.hero .hero-image > div picture {
          height: auto;
          overflow: visible; } }
      section.hero .hero-image > div picture img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center; }
  section.hero .grid {
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-columns: 1fr;
    gap: 0px 0px;
    grid-template-areas: "text" "image"; }
    @media screen and (min-width: 768px) {
      section.hero .grid {
        padding: 2em 6em; } }
    @media screen and (min-width: 1024px) {
      section.hero .grid {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: "text image"; } }

section.summary > div {
  padding: 1em 0; }
  @media screen and (min-width: 768px) {
    section.summary > div {
      padding: 0 2em; } }

@media screen and (min-width: 768px) {
  section.summary .grid {
    padding: 0 6em; } }

@media screen and (min-width: 1024px) {
  section.summary .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: "text text text ."; } }

@media screen and (min-width: 1024px) {
  section.summary .grid p {
    grid-area: text;
    margin-top: 8rem;
    margin-bottom: 7rem; } }

@media screen and (min-width: 768px) {
  section.services {
    margin-top: 10rem; } }

@media screen and (min-width: 1024px) {
  section.services {
    margin: 0; } }

section.services h4 {
  color: #BF9454;
  letter-spacing: 2px;
  margin: 4rem 0px 0rem; }
  @media screen and (min-width: 768px) {
    section.services h4 {
      margin: 0; } }

section.services > div {
  padding: 0; }
  @media screen and (min-width: 768px) {
    section.services > div {
      padding-left: 8rem;
      margin-top: 3rem;
      margin-bottom: 6rem; } }
  section.services > div ul {
    list-style-type: none;
    margin-bottom: 5rem; }
    @media screen and (min-width: 768px) {
      section.services > div ul {
        margin-bottom: 0; } }
    section.services > div ul li {
      padding-bottom: 10px; }
      @media screen and (min-width: 768px) {
        section.services > div ul li {
          padding-bottom: 3px; } }
      @media screen and (min-width: 1024px) {
        section.services > div ul li {
          padding-bottom: 10px; } }
      @media screen and (min-width: 1024px) {
        section.services > div ul li a {
          padding-bottom: 10px; } }

section.quote {
  position: relative; }
  section.quote::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 24%;
    background: #BE945F;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -2; }
  section.quote > div {
    background: -webkit-gradient(linear-gradient(90deg, #b38141 13%, #bf9454 24%, #e4cf90 68%, #e4cf90 100%));
    background: #816042;
    margin-top: 0;
    padding: 1rem 2rem; }
    @media screen and (min-width: 1024px) {
      section.quote > div {
        padding: 4rem 2rem; } }
    @media screen and (min-width: 1200px) {
      section.quote > div {
        margin-top: 13rem; } }
  @media screen and (min-width: 768px) {
    section.quote .grid {
      margin-top: 1rem;
      margin-bottom: 2rem;
      padding: 0 6rem; } }
  @media screen and (min-width: 1200px) {
    section.quote .grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 0px 0px;
      grid-template-areas: "text text text .";
      margin-top: 3rem;
      margin-bottom: 3rem;
      position: relative; }
      section.quote .grid::before {
        content: "";
        background: url(/assets/img/quotes.svg) no-repeat top right;
        background-size: 222px;
        position: absolute;
        z-index: 2;
        top: 0;
        right: 7rem;
        bottom: 0;
        width: 224px;
        height: 168px;
        margin: auto;
        filter: invert(32%) sepia(4%) saturate(3858%) hue-rotate(347deg) brightness(94%) contrast(98%); } }
  section.quote .grid blockquote {
    text-indent: -0.7em;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media screen and (min-width: 1024px) {
      section.quote .grid blockquote {
        text-indent: -1.10em;
        grid-area: text; } }
    section.quote .grid blockquote p {
      margin-top: 0; }
      @media screen and (min-width: 768px) {
        section.quote .grid blockquote p {
          line-height: 4rem; } }
    section.quote .grid blockquote cite {
      color: #E9D495;
      font-style: normal;
      text-indent: 0rem; }

section.be-in-the-know {
  text-align: center;
  background-color: #F7F5E9; }
  @media screen and (min-width: 1200px) {
    section.be-in-the-know {
      background: url(/assets/img/blog-building-test-2.jpg) no-repeat 69% 13%; } }
  section.be-in-the-know h3 {
    color: #BF9454; }
    @media screen and (min-width: 1024px) {
      section.be-in-the-know h3 {
        margin-top: 6rem;
        margin-bottom: 4rem; } }
    @media screen and (min-width: 1200px) {
      section.be-in-the-know h3 {
        margin-top: 12rem;
        margin-bottom: 6rem; } }
  @media screen and (min-width: 1200px) {
    section.be-in-the-know ul {
      margin-bottom: 4em; } }
  section.be-in-the-know li {
    list-style-type: none;
    padding-top: 1rem;
    border-bottom: 2px solid #BF9454;
    padding-bottom: 3rem; }
    section.be-in-the-know li:first-child {
      padding-top: 0; }
    section.be-in-the-know li p {
      margin-bottom: 0;
      padding: 1.5rem; }
    @media screen and (min-width: 1024px) {
      section.be-in-the-know li {
        padding: 3rem 9rem 2rem; } }
  section.be-in-the-know li:last-child {
    border: none; }

section.newsletter-subscription {
  background-color: #BE945F; }
  @media screen and (min-width: 768px) {
    section.newsletter-subscription {
      padding: 4rem 0; } }
  @media screen and (min-width: 1024px) {
    section.newsletter-subscription {
      padding: 4rem 2rem; } }
  section.newsletter-subscription > div {
    padding: 4rem 0; }
    section.newsletter-subscription > div .grid.generic-button, section.newsletter-subscription > div .grid.button-big {
      padding: 15px 40px 14px; }
    @media screen and (min-width: 768px) {
      section.newsletter-subscription > div .grid {
        padding: 2em 4em;
        text-align: center;
        display: grid;
        grid-template-columns: 1fr 1fr; } }
    @media screen and (min-width: 1200px) {
      section.newsletter-subscription > div .grid {
        display: grid;
        grid-template-rows: 100%;
        grid-template-columns: 1.5fr auto;
        align-items: center;
        padding: 2em 6em; } }
    section.newsletter-subscription > div .grid > div:first-child {
      position: relative; }
      section.newsletter-subscription > div .grid > div:first-child div.error {
        position: absolute;
        background-color: #F7F5E9;
        color: #252525;
        padding: 0.5rem 1rem;
        margin: auto;
        height: fit-content;
        width: max-content;
        left: 0;
        right: 0;
        bottom: -2.5rem; }
        section.newsletter-subscription > div .grid > div:first-child div.error::before {
          content: "";
          position: absolute;
          border-left: 0.5rem solid transparent;
          border-right: 0.5rem solid transparent;
          border-top: 0.5rem solid transparent;
          border-bottom: 0.5rem solid #F7F5E9;
          top: -1rem;
          left: 0;
          right: 0;
          width: 0px;
          margin: auto; }
    section.newsletter-subscription > div .grid input[type="email"] {
      padding: 1.3rem;
      width: 100%;
      background: #9F7B53;
      border: none;
      margin-bottom: 1rem;
      color: #fff;
      font-size: 1.9rem;
      text-align: center;
      font-family: "BwDarius-Light", serif;
      border-radius: inherit; }
      @media screen and (min-width: 768px) {
        section.newsletter-subscription > div .grid input[type="email"] {
          font-size: 2.5rem;
          margin: 0; } }
      @media screen and (min-width: 1200px) {
        section.newsletter-subscription > div .grid input[type="email"] {
          text-align: left;
          font-size: 2.3rem; } }
      section.newsletter-subscription > div .grid input[type="email"]:focus {
        outline: none; }
      @media screen and (min-width: 1200px) {
        section.newsletter-subscription > div .grid input[type="email"] {
          width: 100%;
          margin-bottom: 0;
          padding-left: 3rem; } }
    section.newsletter-subscription > div .grid input[type="submit"] {
      width: 100%; }
      @media screen and (min-width: 768px) {
        section.newsletter-subscription > div .grid input[type="submit"] {
          padding: 18px 8px 15px;
          margin: 0;
          font-size: 1.5rem;
          height: 100%; } }
      @media screen and (min-width: 1024px) {
        section.newsletter-subscription > div .grid input[type="submit"] {
          padding: 18px 18px 16px;
          font-size: 1.7rem; } }
      @media screen and (min-width: 1200px) {
        section.newsletter-subscription > div .grid input[type="submit"] {
          padding: 18px 18px 13px;
          font-size: 1.7rem; } }
    section.newsletter-subscription > div p.success-message {
      color: #EFD6C0;
      text-align: center;
      margin: 0; }
      @media screen and (min-width: 768px) {
        section.newsletter-subscription > div p.success-message {
          padding: 0 4rem; } }
      @media screen and (min-width: 1024px) {
        section.newsletter-subscription > div p.success-message {
          padding: 0 6rem; } }

section.hero-challenges > div {
  padding: 15rem 0 0 0; }
  @media screen and (min-width: 1024px) {
    section.hero-challenges > div {
      padding-top: 24rem;
      padding-bottom: 21rem; } }
  @media screen and (min-width: 768px) {
    section.hero-challenges > div .grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 0px 0px;
      grid-template-areas: "animation text text"; } }
  @media screen and (min-width: 1024px) {
    section.hero-challenges > div .grid {
      grid-template-columns: 1.1fr 1.2fr 0.1fr 1.4fr 1.1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas: "animation animation . text text" "animation animation . text text" "animation animation . text text"; } }
  section.hero-challenges > div .grid > div:first-child {
    grid-area: animation;
    position: relative; }
  section.hero-challenges > div .grid > div:last-child {
    grid-area: text; }
    @media screen and (min-width: 1024px) {
      section.hero-challenges > div .grid > div:last-child {
        padding-right: 3.5rem; } }
    section.hero-challenges > div .grid > div:last-child > div:first-child {
      position: relative; }
      @media screen and (min-width: 768px) {
        section.hero-challenges > div .grid > div:last-child > div:first-child h4 {
          padding-bottom: 3.5rem;
          margin: 0; } }
      section.hero-challenges > div .grid > div:last-child > div:first-child p {
        margin-bottom: 5rem; }
        @media screen and (min-width: 768px) {
          section.hero-challenges > div .grid > div:last-child > div:first-child p {
            margin-bottom: 3rem;
            margin-top: 0; } }
  @media screen and (min-width: 768px) {
    section.hero-challenges > div .grid .line {
      position: absolute;
      width: 18px;
      border: 1px solid #BF9454;
      transform: translateX(-50%); } }
  @media screen and (min-width: 768px) {
    section.hero-challenges > div .grid .line-1 {
      top: -22%;
      left: 8%;
      height: 544px;
      transform: rotate(-22deg); } }
  @media screen and (min-width: 1024px) {
    section.hero-challenges > div .grid .line-1 {
      top: -5%;
      left: 33%;
      height: 747px; } }
  @media screen and (min-width: 768px) {
    section.hero-challenges > div .grid .line-2 {
      top: -3%;
      left: 1%;
      height: 421px;
      transform: rotate(48deg); } }
  @media screen and (min-width: 1024px) {
    section.hero-challenges > div .grid .line-2 {
      top: -69%;
      left: 18%;
      height: 690px;
      transform: rotate(48deg); } }
  @media screen and (min-width: 768px) {
    section.hero-challenges > div .grid .line-3 {
      top: -34%;
      left: 31%;
      height: 320px;
      transform: rotate(230deg); } }
  @media screen and (min-width: 1024px) {
    section.hero-challenges > div .grid .line-3 {
      height: 775px; } }

section.challenges-cards {
  margin-bottom: 5em; }
  @media screen and (min-width: 1024px) {
    section.challenges-cards {
      margin-bottom: 12rem; } }
  section.challenges-cards div {
    padding: 0; }

section.challenges-article-hero {
  padding-top: 12rem; }
  @media screen and (min-width: 768px) {
    section.challenges-article-hero {
      padding-top: 15rem; } }
  section.challenges-article-hero::before {
    content: "";
    position: absolute;
    background: #F7F5E9;
    top: 0;
    left: 0;
    right: 0;
    height: 20rem;
    z-index: -1; }
    @media screen and (min-width: 768px) {
      section.challenges-article-hero::before {
        height: 40rem;
        padding-top: 200px; } }
  section.challenges-article-hero > div {
    margin-bottom: 3;
    padding: 0; }
    @media screen and (min-width: 768px) {
      section.challenges-article-hero > div {
        padding: 0 2em;
        margin-bottom: 5rem; } }
    @media screen and (min-width: 1024px) {
      section.challenges-article-hero > div {
        margin-bottom: 0;
        padding: 0;
        padding: 4em 0; } }
    @media screen and (min-width: 1200px) {
      section.challenges-article-hero > div {
        padding: 0 2em; } }
  @media screen and (min-width: 1024px) {
    section.challenges-article-hero .grid {
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-template-rows: 1fr;
      gap: 0px 3rem;
      padding: 9rem 6rem; } }
  @media screen and (min-width: 1200px) {
    section.challenges-article-hero .grid {
      grid-template-columns: 2fr 0.7fr; } }
  section.challenges-article-hero .grid > div {
    margin-bottom: 4rem; }
    @media (min-width: 1024px) {
      section.challenges-article-hero .grid > div {
        margin-bottom: 0; } }
    section.challenges-article-hero .grid > div:first-child > figure {
      position: relative;
      margin: 0; }
      section.challenges-article-hero .grid > div:first-child > figure img {
        width: 100%;
        height: auto; }
      section.challenges-article-hero .grid > div:first-child > figure > span {
        background: linear-gradient(90deg, #b38141 13%, #bd9253 24%, #cba768 68%, #e4cf90 100%);
        height: 2rem;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%; }
        @media screen and (min-width: 768px) {
          section.challenges-article-hero .grid > div:first-child > figure > span {
            right: 2rem;
            height: 3rem; } }
        @media screen and (min-width: 1024px) {
          section.challenges-article-hero .grid > div:first-child > figure > span {
            height: 5rem; } }
    section.challenges-article-hero .grid > div:first-child .article-title h4 {
      text-transform: initial;
      padding-top: 5rem; }
    section.challenges-article-hero .grid > div:first-child > div > h4 {
      text-transform: uppercase;
      font-family: "BwDarius-Light", serif;
      margin: 0;
      font-size: 23px;
      margin-bottom: 2rem; }
      @media (min-width: 768px) {
        section.challenges-article-hero .grid > div:first-child > div > h4 {
          font-size: 30px; } }
      @media (min-width: 1024px) {
        section.challenges-article-hero .grid > div:first-child > div > h4 {
          font-size: 33px; } }
    section.challenges-article-hero .grid > div:first-child > div p {
      margin-bottom: 3rem;
      margin-top: 0; }
      @media screen and (min-width: 768px) {
        section.challenges-article-hero .grid > div:first-child > div p {
          margin-bottom: 5rem; } }
      @media screen and (min-width: 1024px) {
        section.challenges-article-hero .grid > div:first-child > div p {
          margin-bottom: 4rem; } }
    section.challenges-article-hero .grid > div:first-child > div .small {
      text-transform: none; }
    section.challenges-article-hero .grid > div:first-child > div ul {
      list-style-type: square;
      padding: 0 2rem;
      margin-bottom: 4rem;
      font-size: 1.5rem; }
      @media (min-width: 768px) {
        section.challenges-article-hero .grid > div:first-child > div ul {
          font-size: 1.7rem; } }
      @media (min-width: 1024px) {
        section.challenges-article-hero .grid > div:first-child > div ul {
          font-size: 1.98rem; } }
      @media screen and (min-width: 1024px) {
        section.challenges-article-hero .grid > div:first-child > div ul {
          margin-bottom: 6rem;
          padding: 0 0rem 0 2.5rem; } }
      section.challenges-article-hero .grid > div:first-child > div ul ::marker {
        color: #E9D495;
        font-size: x-large; }
      section.challenges-article-hero .grid > div:first-child > div ul li {
        line-height: 1.5;
        padding: 1rem 0;
        font-family: "BwDarius-Light", serif; }
        section.challenges-article-hero .grid > div:first-child > div ul li:nth-child(1) {
          padding: 0; }
        section.challenges-article-hero .grid > div:first-child > div ul li:nth-child(4) {
          padding: 0; }
        @media screen and (min-width: 1024px) {
          section.challenges-article-hero .grid > div:first-child > div ul li {
            padding: 1rem 0  0; } }
    section.challenges-article-hero .grid > div:first-child > div a {
      text-transform: uppercase;
      font-size: 1.5rem;
      background: url(/assets/img/arrow-solid.svg) no-repeat center left;
      background-size: 1.8rem;
      padding: 1rem 0 1rem 3rem; }
      section.challenges-article-hero .grid > div:first-child > div a::before {
        padding: 1rem 0 1rem 3rem; }
      @media screen and (min-width: 768px) {
        section.challenges-article-hero .grid > div:first-child > div a {
          background-size: 2rem; } }
      @media screen and (min-width: 1024px) {
        section.challenges-article-hero .grid > div:first-child > div a {
          font-size: 1.7rem; } }
    section.challenges-article-hero .grid > div:last-child > div {
      background: #252525;
      text-align: center;
      padding: 2rem 1rem 3rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      section.challenges-article-hero .grid > div:last-child > div h3 {
        text-transform: uppercase;
        color: #BF9454;
        margin: 0; }
      section.challenges-article-hero .grid > div:last-child > div p {
        color: #fff;
        margin: 0;
        padding: 0rem 2rem 2rem;
        margin: 1rem 0; }

section.hero-contact > div {
  padding: 15rem 0 0 0; }
  @media screen and (min-width: 1024px) {
    section.hero-contact > div {
      padding-top: 24rem;
      padding-bottom: 16rem; } }
  section.hero-contact > div .grid {
    position: relative; }
    @media screen and (min-width: 768px) {
      section.hero-contact > div .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 0px 0px;
        grid-template-areas: "animation text text";
        padding: 0; } }
    @media screen and (min-width: 1024px) {
      section.hero-contact > div .grid {
        grid-template-columns: 1.1fr 1.2fr 0.1fr 1.4fr 1.1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-areas: "animation animation . text text" "animation animation . text text" "animation animation . text text";
        padding: 0 0 4rem 0; } }
    section.hero-contact > div .grid > div:first-child {
      grid-area: animation; }
      @media screen and (min-width: 768px) {
        section.hero-contact > div .grid > div:first-child .line {
          position: absolute;
          width: 18px;
          border: 1px solid #BF9454;
          transform: translateX(-50%); } }
      @media screen and (min-width: 768px) {
        section.hero-contact > div .grid > div:first-child .line-1 {
          top: -16%;
          left: 8%;
          height: 473px;
          transform: rotate(-22deg); } }
      @media screen and (min-width: 1024px) {
        section.hero-contact > div .grid > div:first-child .line-1 {
          top: -49%;
          left: 11%;
          height: 747px; } }
      @media screen and (min-width: 768px) {
        section.hero-contact > div .grid > div:first-child .line-2 {
          top: -69%;
          left: 8%;
          height: 323px;
          transform: rotate(48deg); } }
      @media screen and (min-width: 1024px) {
        section.hero-contact > div .grid > div:first-child .line-2 {
          top: -78%;
          left: 8%;
          height: 690px;
          transform: rotate(48deg); } }
      @media screen and (min-width: 768px) {
        section.hero-contact > div .grid > div:first-child .line-3 {
          top: -29%;
          left: 6%;
          height: 320px;
          transform: rotate(230deg); } }
      @media screen and (min-width: 1024px) {
        section.hero-contact > div .grid > div:first-child .line-3 {
          height: 700px;
          top: -61%;
          left: 10%; } }
    section.hero-contact > div .grid > div:last-child {
      grid-area: text; }
      @media screen and (min-width: 1024px) {
        section.hero-contact > div .grid > div:last-child {
          padding-right: 3.5rem; } }
      section.hero-contact > div .grid > div:last-child > div:first-child p {
        margin-bottom: 0; }
        @media screen and (min-width: 768px) {
          section.hero-contact > div .grid > div:last-child > div:first-child p {
            margin: 0; } }
      @media screen and (min-width: 768px) {
        section.hero-contact > div .grid > div:last-child > div:first-child > div {
          display: grid;
          grid-template-columns: 1fr 1fr; } }
      section.hero-contact > div .grid > div:last-child > div:first-child > div h4 {
        text-transform: uppercase;
        margin-bottom: 0.5rem;
        margin-top: 0;
        font-size: "BwDarius-Light", serif; }
        @media screen and (min-width: 768px) {
          section.hero-contact > div .grid > div:last-child > div:first-child > div h4 {
            margin-bottom: 2rem; } }
      section.hero-contact > div .grid > div:last-child > div:first-child > div a {
        text-decoration: none; }
      section.hero-contact > div .grid > div:last-child > div:first-child > div > div a {
        font-size: 2.2rem; }
        @media screen and (min-width: 768px) {
          section.hero-contact > div .grid > div:last-child > div:first-child > div > div a {
            font-size: 2.5rem; } }
      section.hero-contact > div .grid > div:last-child > div:first-child > div > div:first-child {
        margin-bottom: 1rem; }
        @media screen and (min-width: 768px) {
          section.hero-contact > div .grid > div:last-child > div:first-child > div > div:first-child {
            margin-bottom: 0; } }
      section.hero-contact > div .grid > div:last-child > div:first-child > span {
        background: linear-gradient(90deg, #b38141 13%, #bd9253 24%, #cba768 68%, #e4cf90 100%);
        height: 2rem;
        margin: 3rem 0;
        display: inline-block; }

div.form-contact {
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem; }
  @media screen and (min-width: 768px) {
    div.form-contact {
      padding-top: 2rem;
      padding-left: 4rem;
      padding-right: 4rem; } }
  div.form-contact > div {
    padding: 6rem 0; }
    @media screen and (min-width: 768px) {
      div.form-contact > div {
        padding: 11rem 0; } }
    @media screen and (min-width: 1024px) {
      div.form-contact > div {
        padding: 4rem 0; } }
    div.form-contact > div:first-child > div {
      text-align: center;
      margin-bottom: 4rem; }
      @media screen and (min-width: 768px) {
        div.form-contact > div:first-child > div {
          margin-bottom: 7rem; } }
      div.form-contact > div:first-child > div h4 {
        text-transform: uppercase;
        margin-top: 0; }
      @media screen and (min-width: 768px) {
        div.form-contact > div:first-child > div p {
          padding: 0 11rem; } }
      @media screen and (min-width: 1024px) {
        div.form-contact > div:first-child > div p {
          padding: 0 25rem; } }
      @media screen and (min-width: 1200px) {
        div.form-contact > div:first-child > div p {
          padding: 0 34rem; } }
    @media screen and (min-width: 768px) {
      div.form-contact > div > form .form-fields {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        gap: 1rem 3rem; } }
    @media screen and (min-width: 1024px) {
      div.form-contact > div > form .form-fields {
        gap: 5rem; } }
    div.form-contact > div > form .form-fields > div {
      position: relative;
      margin-bottom: 3rem; }
      @media screen and (min-width: 1024px) {
        div.form-contact > div > form .form-fields > div {
          margin: 0; } }
      div.form-contact > div > form .form-fields > div > input {
        appearance: none;
        border: none;
        border-bottom: 2px solid #BF9454;
        border-radius: inherit;
        width: 100%;
        padding: 1rem;
        outline: none;
        transition: all 1s ease-out;
        font-family: "BwDarius-Light", serif;
        font-size: 1.9rem; }
        @media screen and (min-width: 768px) {
          div.form-contact > div > form .form-fields > div > input {
            font-size: 2rem; } }
        @media screen and (min-width: 1024px) {
          div.form-contact > div > form .form-fields > div > input {
            font-size: 2.2rem; } }
        div.form-contact > div > form .form-fields > div > input:focus {
          border-bottom: 2px solid #252525; }
        div.form-contact > div > form .form-fields > div > input::placeholder {
          color: #9A9A9A;
          text-align: left;
          font-size: 1.9rem;
          border-radius: inherit; }
          @media screen and (min-width: 768px) {
            div.form-contact > div > form .form-fields > div > input::placeholder {
              font-size: 2rem; } }
          @media screen and (min-width: 1024px) {
            div.form-contact > div > form .form-fields > div > input::placeholder {
              font-size: 2.2rem; } }
      div.form-contact > div > form .form-fields > div div.error {
        position: absolute;
        background-color: #F7F5E9;
        color: #252525;
        padding: 0.5rem 1rem;
        margin: auto;
        height: fit-content;
        width: max-content;
        left: 0;
        right: 0;
        bottom: -2.5rem; }
        div.form-contact > div > form .form-fields > div div.error::before {
          content: "";
          position: absolute;
          border-left: 0.5rem solid transparent;
          border-right: 0.5rem solid transparent;
          border-top: 0.5rem solid transparent;
          border-bottom: 0.5rem solid #F7F5E9;
          top: -1rem;
          left: 0;
          right: 0;
          width: 0px;
          margin: auto; }
    div.form-contact > div > form .message {
      margin-top: 5rem; }
      @media screen and (min-width: 768px) {
        div.form-contact > div > form .message {
          margin-top: 3rem; } }
      @media screen and (min-width: 1024px) {
        div.form-contact > div > form .message {
          margin-top: 5rem; } }
      div.form-contact > div > form .message > textarea {
        width: 100%;
        border: 2px solid #BF9454;
        border-radius: inherit;
        resize: none;
        min-height: 15rem;
        font-family: "BwDarius-Light", serif;
        font-size: 2.2rem;
        outline: none;
        padding: 1rem;
        font-size: 1.9rem;
        appearance: none; }
        @media screen and (min-width: 768px) {
          div.form-contact > div > form .message > textarea {
            font-size: 2rem; } }
        @media screen and (min-width: 1024px) {
          div.form-contact > div > form .message > textarea {
            font-size: 2.2rem; } }
    div.form-contact > div > form .captcha-check {
      margin-top: 4rem; }
      div.form-contact > div > form .captcha-check .g-recaptcha > div {
        margin: 0 auto; }
      div.form-contact > div > form .captcha-check .freeform-form-has-errors {
        text-align: center;
        margin-top: 2rem;
        font-size: 1.6rem; }
    div.form-contact > div > form .cta {
      margin: 4rem 0 6rem 0; }
      div.form-contact > div > form .cta > input {
        margin: auto;
        appearance: none;
        outline: none; }
        div.form-contact > div > form .cta > input:disabled {
          opacity: 0.6; }
      div.form-contact > div > form .cta .success-message {
        text-align: center; }

div.select-contact .select-box {
  position: relative;
  display: block;
  width: 100%;
  font-size: 17px;
  color: #9A9A9A;
  font-family: "BwDarius-Light", serif;
  border-bottom: 2px solid #BF9454;
  z-index: 2; }
  @media screen and (min-width: 1024px) {
    div.select-contact .select-box {
      font-size: 2.2rem; } }
  div.select-contact .select-box.open .select-box__list {
    opacity: 1;
    animation-name: none; }
  div.select-contact .select-box.open .select-box__list .select-box__option {
    cursor: pointer; }
  div.select-contact .select-box.open .select-box__icon {
    transform: translateY(-50%) rotate(180deg); }
  div.select-contact .select-box .select-box__current {
    position: relative;
    cursor: pointer;
    outline: none; }
  div.select-contact .select-box .select-box__icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 20px;
    transition: 0.2s ease; }
  div.select-contact .select-box .select-box__value {
    display: flex; }
  div.select-contact .select-box .select-box__input {
    display: none; }
  div.select-contact .select-box .select-box__input:checked + .select-box__input-text {
    display: block; }
  div.select-contact .select-box .select-box__input-text {
    display: none;
    width: 100%;
    margin: 0;
    padding: 1rem;
    font-size: 1.9rem;
    line-height: 2.6rem; }
    @media screen and (min-width: 768px) {
      div.select-contact .select-box .select-box__input-text {
        line-height: 2.4rem; } }
    @media screen and (min-width: 1024px) {
      div.select-contact .select-box .select-box__input-text {
        font-size: 2.2rem; } }
  div.select-contact .select-box .select-box__list {
    position: absolute;
    width: 100%;
    padding: 0;
    list-style: none;
    opacity: 0;
    animation-name: HideList;
    animation-duration: 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: step-start; }
  div.select-contact .select-box .select-box__option {
    display: block;
    padding: 1rem;
    background-color: #fff;
    border: 1px solid #F7F5E9;
    border-top: none; }
    @media screen and (min-width: 1024px) {
      div.select-contact .select-box .select-box__option {
        font-size: 1.7rem; } }
  div.select-contact .select-box .select-box__option:hover, div.select-contact .select-box .select-box__option:focus {
    color: #fff;
    font-family: "BwDarius-Light", serif;
    background-color: #252525;
    font-size: 1.7rem; }

@keyframes HideList {
  from {
    transform: scaleY(1); }
  to {
    transform: scaleY(0); } }

section.privacy-terms > div {
  padding: 15rem 0 8rem 0; }
  @media screen and (min-width: 1024px) {
    section.privacy-terms > div {
      padding-top: 24rem;
      padding-bottom: 10rem; } }
  section.privacy-terms > div > h4 {
    text-transform: uppercase;
    font-family: "BwDarius-Light", serif;
    margin: 0;
    font-size: 23px;
    margin-bottom: 2rem; }
    @media (min-width: 768px) {
      section.privacy-terms > div > h4 {
        font-size: 30px; } }
    @media (min-width: 1024px) {
      section.privacy-terms > div > h4 {
        font-size: 33px; } }
  section.privacy-terms > div p {
    margin-bottom: 2rem;
    margin-top: 0; }
  section.privacy-terms > div .small {
    text-transform: none; }
  section.privacy-terms > div ul {
    list-style-type: square;
    padding: 0 2rem;
    margin-bottom: 4rem;
    font-size: 1.5rem; }
    @media (min-width: 768px) {
      section.privacy-terms > div ul {
        font-size: 1.7rem; } }
    @media (min-width: 1024px) {
      section.privacy-terms > div ul {
        font-size: 1.98rem; } }
    @media screen and (min-width: 1024px) {
      section.privacy-terms > div ul {
        margin-bottom: 6rem;
        padding: 0 0rem 0 2.5rem; } }
    section.privacy-terms > div ul ::marker {
      color: #E9D495;
      font-size: x-large; }
    section.privacy-terms > div ul li {
      line-height: 1.5;
      font-family: "BwDarius-Light", serif; }
      @media (min-width: 768px) {
        section.privacy-terms > div ul li {
          font-size: 1.7rem;
          padding: 1rem 0; } }
      section.privacy-terms > div ul li:nth-child(4) {
        padding: 0; }
      @media screen and (min-width: 1024px) {
        section.privacy-terms > div ul li {
          padding: 1rem 0  0; } }
  section.privacy-terms > div ol {
    padding: 0 1.5rem;
    margin-bottom: 4rem;
    font-size: 1.5rem;
    font-family: "BwDarius-Light", serif; }
    @media (min-width: 768px) {
      section.privacy-terms > div ol {
        font-size: 1.7rem; } }
    @media screen and (min-width: 1024px) {
      section.privacy-terms > div ol {
        font-size: 1.8rem;
        padding: 0 2rem; } }
    section.privacy-terms > div ol > li {
      padding: 1rem 0; }

section.hero-blog > div {
  padding-top: 15rem; }
  @media screen and (min-width: 1024px) {
    section.hero-blog > div {
      padding-top: 24rem;
      padding-bottom: 12rem; } }
  @media screen and (min-width: 768px) {
    section.hero-blog > div .grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 0px 0px;
      grid-template-areas: "animation text text"; } }
  @media screen and (min-width: 1024px) {
    section.hero-blog > div .grid {
      grid-template-columns: 1.1fr 1.2fr 0.1fr 1.4fr 1.1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas: "animation animation . text text" "animation animation . text text" "animation animation . text text"; } }
  section.hero-blog > div .grid > div:first-child {
    grid-area: animation;
    position: relative; }
  section.hero-blog > div .grid > div:last-child {
    grid-area: text; }
    @media screen and (min-width: 1024px) {
      section.hero-blog > div .grid > div:last-child {
        padding-right: 3.5rem; } }
    section.hero-blog > div .grid > div:last-child > div:first-child h4 {
      padding-bottom: 3.5rem;
      margin: 0;
      color: #fff; }
    section.hero-blog > div .grid > div:last-child > div:first-child p {
      padding-bottom: 4rem;
      margin: 0;
      color: #fff; }
    section.hero-blog > div .grid > div:last-child > span {
      display: inline-block;
      width: 100%;
      height: 1.8rem;
      background: linear-gradient(90deg, #b38141 13%, #bd9253 24%, #cba768 68%, #e4cf90 100%); }

section.read-more-links {
  padding-bottom: 7rem;
  position: relative;
  z-index: 2; }
  @media (min-width: 768px) {
    section.read-more-links {
      padding-bottom: 5rem; } }
  @media (min-width: 1024px) {
    section.read-more-links {
      padding-bottom: 17rem; } }
  section.read-more-links > div {
    padding: 0.7rem 0; }
    @media (min-width: 768px) {
      section.read-more-links > div {
        padding: 4rem 0; } }
    section.read-more-links > div ul {
      list-style-type: none; }
      section.read-more-links > div ul li {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #BF9454;
        padding: 3rem 0;
        color: #fff; }
        @media (min-width: 768px) {
          section.read-more-links > div ul li {
            padding: 4rem 0;
            width: 95%; } }
        section.read-more-links > div ul li p {
          margin-top: 0;
          margin-top: -0.9rem; }
          @media (min-width: 1024px) {
            section.read-more-links > div ul li p {
              line-height: 1.2; } }
        section.read-more-links > div ul li a {
          font-family: "BwDarius-Light", serif;
          text-transform: uppercase;
          font-size: 1.4rem;
          color: #fff; }
        section.read-more-links > div ul li:first-child {
          padding-top: 0; }
        section.read-more-links > div ul li:last-child {
          margin-bottom: 5rem; }
    section.read-more-links > div .button-container .generic-button {
      max-width: 23rem; }

section.blog-internal {
  background-color: #545454;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 85%;
  position: relative; }
  @media screen and (min-width: 1024px) {
    section.blog-internal {
      background-size: 35%; } }
  section.blog-internal:before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    background: linear-gradient(to bottom, #545454 0%, rgba(125, 185, 232, 0) 100%);
    height: 100%;
    z-index: 0; }
    @media screen and (min-width: 768px) {
      section.blog-internal:before {
        top: 7rem; } }
    @media screen and (min-width: 1024px) {
      section.blog-internal:before {
        top: 11rem; } }
