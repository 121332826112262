section.leadership {
    background: $dark-gray;
    position: relative;
    
    @media screen and (min-width: $tablet-BP) { 
        margin: 0px 3rem 15rem;
    }

    @media screen and (min-width: $desktop-BP) {
        margin: 0px 12rem 11rem;
    }

    @media screen and (min-width: $desktop-big-BP) {
        margin: 0px 11rem 15rem;
    }

    & > div {
        position: relative;
        text-align: center;
        @media screen and (min-width: $tablet-BP) { 
            z-index: 2;
            padding: 7rem 0rem;
        }

        @media screen and (min-width: $desktop-BP) {
            padding: 6rem 3rem;
        }

        @media screen and (min-width: $desktop-big-BP) {
            padding: 5rem 20rem;
        }

        p {
            // margin-bottom: 6rem;
            margin-top: 5rem;

            @media screen and (min-width: $tablet-BP) {
                margin-top: 4rem;
                // margin-bottom: 7rem;
            }

            @media screen and (min-width: $desktop-big-BP) {
                font-size: 18px;
            }
        }

        h4 {
            color: $dark-mustard;
            text-transform: uppercase;
        }
        

        .button-container {
            margin-top: 4rem;
            @media screen and (min-width: $tablet-BP) {
                margin-top: 6rem;
            }

            @media screen and (min-width: $desktop-BP) {
                margin-top: 5rem;
            }

            @media screen and (min-width: $desktop-big-BP) {
                margin-top: 4rem;
            }
        }
    }

    // &::before {
    //     @media screen and (min-width: $desktop-big-BP) {
    //         content: "";
    //         position: absolute;
    //         z-index: 2;
    //         top: 0;
    //         left: 0;
    //         right: 0;
    //         height: 100%;
    //         background:url(/assets/img/leadership.png) no-repeat top right;
    //         background-size: auto 188%;
    //     }
    // }
    
}