section.privacy-terms {
    & > div {
        padding: 15rem 0 8rem 0;
        @media screen and (min-width: $desktop-BP) {
            padding-top: 24rem;
            padding-bottom: 10rem
        }

        & > h4 {
            text-transform: uppercase;
            font-family: $ff-serif;
            margin: 0;
            font-size: 23px;
            margin-bottom: 2rem;
            @media(min-width: $tablet-BP) {
                font-size: 30px;
            }
            @media(min-width: $desktop-BP) {
                font-size: 33px;
            }
        }

        p {
            margin-bottom: 2rem;
            margin-top: 0;
        }

        .small {
            text-transform: none;
        }

        ul {
            list-style-type: square;
            padding: 0 2rem;
            margin-bottom: 4rem;   
            font-size: 1.5rem;

            @media(min-width: $tablet-BP) {
                font-size: 1.7rem;
            }

            @media(min-width: $desktop-BP) {
                font-size: 1.98rem;
            }
            

            @media screen and (min-width: $desktop-BP) {
                margin-bottom: 6rem;
                padding: 0 0rem 0 2.5rem;
            }

            ::marker {
                color: $soft-mustard;
                font-size: x-large;
            }

            li {
                line-height: 1.5;
                font-family: $ff-serif;

                @media(min-width: $tablet-BP) {
                    font-size: 1.7rem;
                    padding: 1rem 0
                    ;
                }

                &:nth-child(4){
                    padding: 0;
                }

                @media screen and (min-width: $desktop-BP) {
                    padding: 1rem 0  0; 
                } 
            }
        }

        ol {
            padding: 0 1.5rem;
            margin-bottom: 4rem;   
            font-size: 1.5rem;
            font-family: $ff-serif;

            @media(min-width: $tablet-BP) {
                font-size: 1.7rem;
            }
            
            @media screen and (min-width: $desktop-BP) {
                font-size: 1.8rem;
                padding: 0 2rem;
            }

            & > li {
                padding: 1rem 0;
            }
        }
    }
}