section.discover {

    @media screen and (min-width: $tablet-BP) { 
        padding: 5rem 4rem;
    }
    
    @media screen and (min-width: $desktop-big-BP) { 
        padding: 0
    }

    & > div { 
        text-align: center;
        line-height: 1.5;

        @media screen and (min-width: $desktop-BP) { 
            padding: 3rem 4rem;
        }

        @media screen and (min-width: $desktop-big-BP) { 
            padding: 12rem 27rem;
            line-height: 1.5;
        }

        a {
            text-transform: uppercase;
        }
    }
}