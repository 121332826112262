/****************** GLOBAL *********************/
@font-face {
	font-family: 'BwDarius-Regular';
	src: url('../fonts/webfonts/37C77E_2_0.eot');
	src: url('../fonts/webfonts/37C77E_2_0.eot?#iefix') format('embedded-opentype'),
	url('../fonts/webfonts/37C77E_2_0.woff2') format('woff2'),
	url('../fonts/webfonts/37C77E_2_0.woff') format('woff'),
	url('../fonts/webfonts/37C77E_2_0.ttf') format('truetype');
}

@font-face {
	font-family: 'BwDarius-Light';
	src: url('../fonts/webfonts/BwDariusDEMO-Light.eot');
	src: url('../fonts/webfonts/BwDariusDEMO-Light.eot?#iefix') format('embedded-opentype'),
	url('../fonts/webfonts/BwDariusDEMO-Light.woff2') format('woff2'),
	url('../fonts/webfonts/BwDariusDEMO-Light.woff') format('woff'),
	url('../fonts/webfonts/BwDariusDEMO-Light.ttf') format('truetype');
}

$ff-serif: 'BwDarius-Light', serif; 

$ff-sansSerif: 'Josefin Sans', sans-serif;

// color palette
$black: #252525;
$white: #fff;
$gray: #9A9A9A;
$soft-gray:#858585;
$bg-gray:#545454;
$brown:#7B592B;
$soft-brown:#9F7B53;   
$dark-gray:#252525;
$soft-mustard:#E9D495;
$soft-bg-mustard:#F7F5E9;
$dark-mustard: #BF9454;
$bg-mustard: #BE945F;
$soft-text:#EFD6C0;
$bg-dark-brown: #816042;
$mustard-gradient:rgb(179,129,65);
$dark-gradient:linear-gradient(90deg, rgba($mustard-gradient,1) 13%, rgba(189,146,83,1) 24%, rgba(203,167,104,1) 68%, rgba(228,207,144,1) 100%);
$soft-gradient: linear-gradient(90deg, #b38141 13%, #bf9454 24%, #e4cf90 68%, #e4cf90 100%);
$grad-dark-right:linear-gradient(90deg, rgba(0,0,0,0.6965161064425771) 0%, rgba(37,37,37,1)49%);
$grad-dark-left:linear-gradient(90deg, rgba(37,37,37,1) 45%, rgba(0,0,0,0.48503151260504207) 100%);
$grad-dark:linear-gradient(90deg, rgba(0, 0, 0, 0%) 0%, #252525 49%);
$grad-gray-blog: linear-gradient(to bottom,  rgba(84,84,84,1) 0%,rgba(125,185,232,0) 100%);

/*-----------------------------------*\
    COLOR VARS TRANSPARENCIES
\*-----------------------------------*/

$shadow-light:0 0 20px rgba(0, 0, 0, 0.2);



/*-----------------------------------*\
	CLASS COLORS
\*-----------------------------------*/

.white-c {
	color: $white;
}

.soft-gray {
	color:$soft-gray;
}

// spacing & containers
$sectionPadding: 96px;
$sectionPadding-medium: 60px;
$sectionPadding-small: 30px;
$sectionPaddingTop: 130px;
$sectionPaddingTop-medium: 65px;
$sectionPaddingTop-small: 30px;

/*-----------------------------------*\
	MEDIA QUERIES IN CORRECT ORDER
\*-----------------------------------*/
$mobile-small-BP: 400px;
$mobile-BP: 640px;
$tablet-BP: 768px;
$desktop-BP: 1024px;
$desktop-inter-BP: 1100px;
$desktop-big-BP: 1200px;
$desktop-extra-BP: 1450px;
$desktop-extra-big-BP: 1590px;
$desktop-max-big-BP: 1600x;


/*-----------------------------------*\
	UNIVERSAL
\*-----------------------------------*/
html {
	box-sizing: border-box;
	// background: ;
	font-size: 62.5%; 
	font-family: $ff-sansSerif;
	line-height: 1.1;
    scroll-behavior: smooth;
    
}

html,
body {
	margin: 0;
	padding: 0;
	color: $black;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}
/*-----------------------------------*\
	HELPERS
\*-----------------------------------*/

.o-hidden {
	overflow: hidden;
}
/*-----------------------------------*\
	SKIP NAV
\*-----------------------------------*/
#skip-to-content {
	position: absolute;
	top: 0px;
	left: 0;
	transform: translateY(-800%);
	z-index: 999999;
	transition: all .35s linear;
	// background-color: var(--Mint);
	// color: var(--Black);
	padding: .5em 1em;
	text-decoration: none;

	&:focus {
		transform: translateY(0);
	}
}

/*-----------------------------------*\
	SECTION
\*-----------------------------------*/

section {
	padding: 0 2em;

	@media(min-width: $tablet-BP) {
		padding: 0 4em;
	}



	&>div {
		padding: 4em 0;
		max-width: 1200px;
        margin: 0 auto;

		@media(min-width: $tablet-BP) {
			padding: 4em 2em;
		}
		
		@media(min-width: $desktop-BP) {
			padding:2em 2em;
		}
		
    }
}


/*-----------------------------------*\
	LIST
\*-----------------------------------*/

ol,ul{
	margin: 0;
	padding: 0;
	li{
		padding: 0 0;
	}
}


/*-----------------------------------*\
	MARGIN
\*-----------------------------------*/



.mt-1 {
	margin-top: 1rem;
}


.mt-2 {
	margin-top: 2rem;
}

.mt {
	margin-top: 3rem;
	@media screen and (min-width: $desktop-BP) {
		margin-top: 7rem;
	}
}

.mb {
	margin-bottom: 6.5rem;
}

/*-----------------------------------*\
	CONTAINERS
\*-----------------------------------*/
.container-small {
	@media screen and (min-width: $desktop-BP){
	width: 80%;
	margin: 0 auto;
	}

	@media screen and (min-width: $desktop-big-BP){
		width: 60%;
		margin: 0 auto;
	}
}

/*-----------------------------------*\
	PLACEHOLDER
\*-----------------------------------*/

input {
	&::placeholder,
	&::-webkit-input-placeholder {
		color: $soft-text;
		font-size: 1.9rem;
		text-align: center;
		font-family: $ff-serif;
		@media screen and (min-width: $tablet-BP) {
			font-size:2.5rem;
		}
		@media screen and (min-width: $desktop-big-BP){
			text-align: left;
			font-size: 2.2rem;
		}
	}
	&[type="submit"] {
		appearance: none;
	}
}

/*-----------------------------------*\
	ICONS SOCIAL MEDIA
\*-----------------------------------*/

.ic-sm {
	
	filter: invert(50%);
	margin-top: 3rem;
	max-width: 2.5rem;
	margin-right: 2.6rem;

	@media screen and (min-width: $tablet-BP){
		margin-right: 3rem;
		margin-top: 0;
		
	}

	@media screen and (min-width: $desktop-big-BP){
		margin-top: 0;
		max-width: 2.7rem;
		margin-right: 2rem;
	}
}

/*-----------------------------------*\
	LOGOS
\*-----------------------------------*/

.dark-logo {
	max-width: 9rem;
    width: 12rem;
    height: auto;
	
	@media screen and (min-width: $tablet-BP){
		max-width: 16rem;
		width: 13rem;
	}

	@media screen and (min-width: $desktop-big-BP){
		width: 15em;
    	max-width: 15em;
	}
}

/*-----------------------------------*\
	STICKY
\*-----------------------------------*/

.sticky {		
	@media screen and (min-width: $tablet-BP){
		position: -webkit-sticky;
		position: -moz-sticky;
		position: -ms-sticky;
		position: -o-sticky;
		position: sticky;
		top: 12rem;
		height: 300px;
		will-change: transform;
		
		&.sticky-small {
			height: 206px;
		}

		&.sticky-inter {
			height: 267px;
		}
	}

	@media screen and (min-width: $desktop-BP) {
		top: 5rem;
	}

}

.sticky-desktop {		
	@media screen and (min-width: $desktop-BP){
		position: -webkit-sticky;
		position: -moz-sticky;
		position: -ms-sticky;
		position: -o-sticky;
		position: sticky;
		top: 5rem;
		height: 300px;
		will-change: transform;
		
		&.sticky-small {
			height: 206px;
		}

		&.sticky-inter {
			height: 267px;
		}
	}
	@media screen and (min-width: $tablet-BP) {
		scroll-margin-top: 16rem;
	}
	@media screen and (min-width: $desktop-BP) {
		scroll-margin-top: 17rem;
	}
}

.hide-on-small-only {
	display: none;
	@media(min-width: $tablet-BP) {
		display: block;
	}
}

.hide-on-med-and-down {
	display: none;
	@media(min-width: $desktop-BP) {
		display: block;
	}
}
.hide-on-large-and-up {
	display: block;
	@media(min-width: $desktop-BP) {
		display: none !important;
	}
}
.hide-on-med-and-up {
	display: block;
	@media(min-width: $tablet-BP) {
		display: none !important;
	}
}

.show {
	display: none;
}

/*-----------------------------------*\
	FOR ACCESSIBILITY
\*-----------------------------------*/

a, button, input, select, textarea, h3, .checkcontainer {
	&:focus{
		outline-color: $dark-mustard;
	} 
}

.accessibility-hidden {
    visibility: hidden;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}