/*-----------------------------------*\
	HEADER
\*-----------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	letter-spacing: 0;
	font-weight: 700;
	line-height: 1.1;
}

h1,
.h1 {
	font-size: 40px;

	@media(min-width: $tablet-BP) {
		font-size: 70px;
	}
}

h2,
.h2 {
	font-size: 3rem;

	@media screen and (min-width: $tablet-BP) {
		font-size: 3rem;
	}
	@media screen and (min-width: $desktop-BP) {
		font-size: 5.4rem;
	}

	&.is-big {
		@media(min-width: $tablet-BP) {
			font-size: 4.4rem;
		}
		
		@media screen and (min-width: $desktop-big-BP) {
			font-size: 5.4rem;
		}
	}
}

h3,
.h3 {
	font-size: 30px;

	@media(min-width: $tablet-BP) {
		font-size: 32px;
	}
	@media(min-width: $desktop-big-BP) {
		font-size: 50px;
	}

	&.is-extra {

		font-size: 24px;
		
		@media screen and (min-width: $mobile-small-BP) { 
			font-size: 25px;
		}

		@media screen and (min-width: $mobile-small-BP) { 
			font-size: 23px;
		}


		@media(min-width: $tablet-BP) {
			font-size: 27px;
		}

		@media(min-width: $desktop-BP) {
			font-size: 45px;
		}
	}
}

h4,
.h4 {
	font-size: 20px;

	@media(min-width: $tablet-BP) {
		font-size: 26px;
	}
	@media(min-width: $desktop-BP) {
		font-size: 24px;
	}

	&.big {
		font-size: 23px;
		@media(min-width: $tablet-BP) {
			font-size: 30px;
		}
		@media(min-width: $desktop-BP) {
			font-size: 33px;
		}

		&.inter-big {
			@media(min-width: $desktop-BP) {
				font-size: 41px;
			}
		}
	}

	


}

h5,
.h5 {
	font-size: 16px;

	@media(min-width: $tablet-BP) {
		font-size: 25px;
	}
}

h6,
.h6 {
	font-size: 14px;

	@media(min-width: $tablet-BP) {
		font-size: 16px;
	}
}

/*-----------------------------------*\
	PARAGRAPH
\*-----------------------------------*/
p {
	font-size: 15px;
	line-height: 1.5;
	font-family: $ff-serif;

	@media(min-width: $tablet-BP) {
		font-size: 18px;
	}

	&.is-medium {
		font-size: 1.5rem;

		@media(min-width: $tablet-BP) {
			font-size: 1.7rem;
		}

		@media(min-width: $desktop-BP) {
			font-size: 1.98rem;
		}
	}

	&.is-large {
		font-size: 1.5rem;

		@media(min-width: $tablet-BP) {
			font-size: 22px;
		}
	}

	&.is-large-g {
		font-size: 1.8rem;

		@media(min-width: $desktop-BP) {
			font-size: 2.3rem;
		}
	}

	&.is-extra-large {
		font-size: 20px;
		line-height: 1.5;

		@media(min-width: $tablet-BP) {
			font-size: 50px;
			line-height: 1.2;
		}
	}

	&.is-large-bold {
		font-size: 17px;
		font-weight: 700;

		@media(min-width: $tablet-BP) {
			font-size: 28px;
		}
	}

	&.is-long {
		@media(min-width: $desktop-BP) {
			font-size: 3.1rem;
		}
	}

	&.is-serif {  //la cursiva
		font-family: $ff-serif;
	}
	&.is-sansSerif{ //la no cursiva
		font-family: $ff-sansSerif;
	}

	&.is-serif-large {
		font-family: $ff-serif;
		font-size: 15px;

		@media(min-width: $tablet-BP) {
			font-size: 20px;
		}
	}

	&.is-serif-extra-large {
		font-family: $ff-serif;
		font-size: 21px;

		@media(min-width: $tablet-BP) {
			font-size: 50px;
		}
	}

	&.is-bold {
		font-weight: bold;
	}

	&.is-cite {
		@media(min-width: $tablet-BP) {
			font-size: 23px;
		}
	}

	&.is-large-medium {
		@media(min-width: $desktop-BP) {
			font-size: 2.4rem;
		}
	}

	&.text-big {
		@media screen and (min-width: $desktop-BP) {
			font-size: 33px;
			line-height: 46px;
		}
	}

}

/*-----------------------------------*\
	EXTRAS FONT SIZE
\*-----------------------------------*/

.is-so-small {
	font-size: 14px;
}

.inter {
	@media(min-width: $desktop-BP) {
		font-size: 27px;
	}
}

.large {
	font-size: 19px;
	@media(min-width: $desktop-BP) {
		font-size: 28px;
	}
}

.medium-text {
	@media(min-width: $desktop-BP) {
		font-size: 2.2rem;
	}
}

a, p a {
	color: $black;
	text-decoration: underline;

	// &:hover,
	// &:focus {
	// 	background-color: var(--Mint);
	// }

}

.pp {
	color: $white;
}

/*-----------------------------------*\
	HELPERS
\*-----------------------------------*/
.fw {
	font-weight: lighter;
}

.fw-b {
	font-weight: bold;
}

.ls {
	letter-spacing: 1px;
}


.ls-2 {
	letter-spacing: 2px;
}

.ls-4 {
	letter-spacing: 4px;
}

.lh {
	line-height: 2.6rem;
	@media screen and (min-width: $tablet-BP) {
		line-height: 3.3rem;
	}
}

.lh-inter {
	line-height: 1.5;
}

.lh-3 {
	@media screen and (min-width: $tablet-BP) {
		line-height: 3.4rem;
	}
}

.lh-g {
	line-height: 2.6rem;
	@media screen and (min-width: $tablet-BP) {
		line-height: 3rem;
	}
	@media screen and (min-width: $desktop-BP) {
		line-height: 3.7rem;
	}
	
}

.lh-m {
	line-height: 1.3;
}

.lh-4 {
	
	@media screen and (min-width: $tablet-BP) {
		line-height: 3rem;
	}

	@media screen and (min-width: $desktop-BP) {
		line-height: 4.5rem;
	}
}

.list-medium {
	font-size: 1.5rem;

	@media(min-width: $tablet-BP) {
		font-size: 1.7rem;
	}

	@media(min-width: $desktop-BP) {
		font-size: 1.98rem;
	}
}


.only-t-fotter {
	@media (min-width: $desktop-BP) {
		font-size: 3.7rem;
	}
}

.ta-c {
	text-align: center;
}

.is-serif {  //la cursiva
	font-family: $ff-serif;
}
.is-sansSerif{ //la no cursiva
	font-family: $ff-sansSerif;
}

/*-----------------------------------*\
	HORIZONTAL RULE
\*-----------------------------------*/
hr {
	margin: 3em auto;
	height: 3px;
	background-color: $black;
	border: none;

	&.is-style-wide {
		margin: 6em auto;
		max-width: 1024px;
	}

	&.is-style-dots::before {
		font-weight: bold;
		font-size: 35px;
	}
}

/*-----------------------------------*\
	Cite
\*-----------------------------------*/

.cite-author {
	font-size: 1.5rem;

	@media screen and (min-width: $tablet-BP) {
		font-size: 2rem;
	}
}


