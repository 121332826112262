section.organization {
    overflow: hidden;

    & > div {
        position: relative;
        
        @media screen and (min-width: $tablet-BP) {
            padding-bottom: 4rem;
            padding-top: 0rem;
        }

        .line {
            @media screen and (min-width: $tablet-BP) {
                position: absolute;
                width: 18px;
                border: 1px solid $dark-mustard;
                transform: translateX(-50%);
            }
        }

        .line-1 {
            @media screen and (min-width: $tablet-BP) {
                top: 1rem;
                right: 3%;
                height: 714px;
                transform: rotate(46deg);
            }
        }

        .line-2 {
            @media screen and (min-width: $tablet-BP) {
                top: 7rem;
                right: 4%;
                height: 450px;
                transform: rotate(-1deg);
            }
        }

        .line-3 {
            @media screen and (min-width: $tablet-BP) {
                top: 7rem;
                right: 4%;
                height: 450px;
                transform: rotate(-24deg);
            }
        }
    }


    .grid {
        @media screen and (min-width: $tablet-BP) {
            display: grid;
            grid-template-columns: 0.4fr 1fr 1.7fr 1fr 1fr 1fr;
            gap: 0px 0px;
            grid-template-areas:
            ". p1 p1 p1 p1 ."
            ". p2 p2 . . .";
        }

        @media screen and (min-width: $desktop-BP) {
            grid-template-rows: 1fr;  
        }

        @media screen and (min-width: $desktop-big-BP) {
            grid-template-rows: 1fr 1fr;  
        }

        & > div {
            &:first-child {
                grid-area: p1;

                p {
                    @media screen and (min-width: $tablet-BP) {
                        margin: 0;
                        padding-top: 12rem;
                        padding-bottom: 10rem;
                    }

                    @media screen and (min-width: $desktop-BP) {
                        padding-top: 12.7rem;
                        padding-bottom: 7.1rem;
                    }
                }
            }

            &:last-child {
                grid-area: p2;

                h4 {
                    color: $dark-mustard;
                    margin-top: 5rem; //new
                    text-transform: uppercase;

                    @media screen and (min-width: $desktop-BP) {
                        line-height: 3.7rem; 
                    }
                }

                p {
                    @media screen and (min-width: $desktop-BP) {
                        padding-right: 1em;
                    }  
                }
            }
        }
    }
}