section.services {
    @media screen and (min-width: $tablet-BP) {
        margin-top: 10rem;
    }
    @media screen and (min-width: $desktop-BP) {
        margin: 0;
    }

    h4 {
        color: $dark-mustard;
        letter-spacing: 2px;
        margin: 4rem 0px 0rem;

        @media screen and (min-width: $tablet-BP) {
            margin: 0;
        }
    }

    & > div {
        padding: 0;
        
        @media screen and (min-width: $tablet-BP) {
            padding-left: 8rem;
            margin-top: 3rem;
            margin-bottom: 6rem;
        }

        ul {
            list-style-type: none;
            margin-bottom: 5rem;

            @media screen and (min-width: $tablet-BP) {
                margin-bottom: 0;
            }

            li {
                padding-bottom: 10px;

                @media screen and (min-width: $tablet-BP) {
                    padding-bottom: 3px;
                }
                @media screen and (min-width: $desktop-BP) {
                    padding-bottom: 10px;
                }

                a {
                    @media screen and (min-width: $desktop-BP) {
                        padding-bottom: 10px;
                    }  
                }
            }
        }
    }
}
