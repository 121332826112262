.right-hover {
    text-decoration: none;
    position: relative;
    display: inline-block;
    overflow: visible;
    
    &::before {
        position: absolute;
        content: attr(data-content);
        top: 0;
        left: 0;
        width: 0;
        color: $dark-mustard;
        text-decoration: none;
        white-space: nowrap;
        overflow: hidden;
        transition: width 250ms ease;
        display: inline-block;
    }

    @media screen and (min-width: $desktop-BP) {
        &:hover {
            color:$dark-mustard !important;
            transition: color 350ms ease;
        }
        &:hover::before {
            width: 100%;
        }
    }

}

.hvr-fade {
    -moz-transition: color .1s ease-in;
    -o-transition: color .1s ease-in;
    -webkit-transition: color .1s ease-in;
    transition: color .1s ease-in;
    @media screen and (min-width: $desktop-BP) {
        &:hover {
            color: $dark-mustard;
        }
    }
}

/* Underline From Left */
.hvr-underline-from-left {
    & > a {
        text-decoration: none;
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        position: relative;
        overflow: hidden;
        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            left: 0;
            right: 100%;
            bottom: 0;
            background: $soft-gradient;
            height: 4px;
            -webkit-transition-property: right;
            transition-property: right;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
        }
    }
    @media screen and (min-width: $desktop-BP) {
        &:hover > a:before,
        &:focus > a:before,
        &:active > a:before {
            right: 0;
        }
    }
}

/* Underline From Left little */
.hvr-underline-from-left-little {
    & > a {
        text-decoration: none;
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        position: relative;
        overflow: hidden;
        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            left: 0;
            right: 100%;
            bottom: 0;
            background: $white;
            height: 1px;
            -webkit-transition-property: right;
            transition-property: right;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
        }
    }
    @media screen and (min-width: $desktop-BP) {
        &:hover > a:before,
        &:focus > a:before,
        &:active > a:before {
            right: 0;
        }
    }
}

.hvr-line {
    position: relative;
    display: inline-block;
    color: $black;
    overflow: hidden;
    &::before {
        position: absolute;
        content: attr(data-content);
        top: 0;
        left: 0;
        width: 0;
        color: $dark-mustard;
        text-decoration: underline;
        white-space: nowrap;
        overflow: hidden;
        transition: width 575ms ease;
        display: inline-block;
        // white-space: pre-wrap;
    }

    @media screen and (min-width: $desktop-BP) {
        &:hover::before {
            width: 100%;
        }
    }
    
    &.multiline {
        &::before {
            white-space: pre-wrap;
            text-indent: 1px;
            transition: width 200ms ease;
        }
    }

    &.change-color {
        color: $white;
    }

}

.hvr-scale {        
    transition: all .3s ease-in-out;
    @media screen and (min-width: $desktop-BP) {
        &:hover {
            transform: scale(1.03);
            transition: all .3s ease-in-out;
        }
    }
}

.hvr-svg-color{        
    transition: all .2s ease-in-out;
    @media screen and (min-width: $desktop-BP) {
        &:hover {
            transition: all .2s ease-in-out;
            filter: invert(65%) sepia(24%) saturate(718%) hue-rotate(354deg) brightness(91%) contrast(84%);
        }
    }
}