div.select-contact {
    .select-box {
        position: relative;
        display: block;
        width: 100%;
        font-size: 17px;
        color: $gray;
        font-family: $ff-serif;
        border-bottom: 2px solid $dark-mustard;
        z-index: 2;

        @media screen and (min-width: $desktop-BP) {
            font-size: 2.2rem;
        }

        &.open { // UL element is displayed (dropdown)
            .select-box__list {
                opacity: 1;
                animation-name: none;
            }
            .select-box__list .select-box__option {
                cursor: pointer;
            }
            .select-box__icon {
                transform: translateY(-50%) rotate(180deg);
            }
        }

        .select-box__current {
            position: relative;
            cursor: pointer;
            outline: none;
        }
        
        .select-box__icon {
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            width: 20px;
            transition: 0.2s ease;
        }
        .select-box__value {
            display: flex;
        }
        .select-box__input {
            display: none;
        }
        .select-box__input:checked + .select-box__input-text {
            display: block;
        }
        .select-box__input-text {
            display: none;
            width: 100%;
            margin: 0;
            padding: 1rem;
            font-size: 1.9rem;
            line-height: 2.6rem;
            @media screen and (min-width: $tablet-BP) {
                line-height: 2.4rem;
            }
            @media screen and (min-width: $desktop-BP) {
                font-size: 2.2rem; //inicial
            }
        }
        .select-box__list { // UL element is hidden (dropdown)
            position: absolute;
            width: 100%;
            padding: 0;
            list-style: none;
            opacity: 0;
            animation-name: HideList;
            animation-duration: 0.5s;
            animation-delay: 0.5s;
            animation-fill-mode: forwards;
            animation-timing-function: step-start;
        }
        .select-box__option {
            display: block;
            padding: 1rem;
            background-color: #fff;
            border: 1px solid $soft-bg-mustard;
            border-top: none;
            @media screen and (min-width: $desktop-BP) {
                font-size: 1.7rem; //internal sin hover
            }
        }
        .select-box__option:hover, .select-box__option:focus {
            color: $white;
            font-family: $ff-serif;
            background-color: $black;
            font-size: 1.7rem; //internal hover
        }
        
        @keyframes HideList {
            from {
                transform: scaleY(1);
            }
            to {
                transform: scaleY(0);
            }
        }
    }
}