section.advantages {

    background: $soft-bg-mustard;
    padding-top: 2rem;

    @media screen and (min-width: $tablet-BP) {
        padding-top: 3rem;
    }

    @media screen and (min-width: $desktop-big-BP) {
        padding-top: 13rem;
    }
    
    .grid {
        
        @media screen and (min-width: $tablet-BP) {  
            display: grid;
            grid-template-columns:1fr;
            grid-template-rows: 1fr 1fr;
            gap: 0px 0px;
            grid-template-areas:
            "area1 area2"
            ". area2";
        }

        @media screen and (min-width: $desktop-BP) {  
            grid-template-columns: 1fr 1fr;
            padding-bottom: 10rem;
        }

        & > div:first-child {
            
            @media screen and (min-width: $tablet-BP) {  
                grid-area: area1;
            }

            @media screen and (min-width: $tablet-BP) {  
                padding: 0 3rem;
            }

            @media screen and (min-width: $desktop-big-BP) {  
                padding: 0 7rem;
            }

            & > h3 {
                text-transform: uppercase;
            }

            & > h3:first-child {
                border-bottom: 2px solid $dark-mustard;
            }

            & > h3:last-child {
                padding-top: 1rem;
                margin: 0;
            }

            h3 {
                margin-right: 6rem;
                padding-bottom: 1rem;

                @media screen and (min-width: $mobile-small-BP) { 
                    margin-right: 24rem;
                }
                @media screen and (min-width: $mobile-BP) { 
                    margin-right: 31rem;
                }
                @media screen and (min-width: $tablet-BP) { 
                    margin-right: 0;
                }

                @media screen and (min-width: $desktop-BP) { 
                    margin-right: 3rem;
                    line-height: 55px;
                }
            }
        }
    
        & > div:last-child {
            grid-area: area2;
            padding-top: 2rem;
        
            @media screen and (min-width: $tablet-BP) { 
                padding: 0 2rem;
            }
            
            @media screen and (min-width: $desktop-BP) { 
                padding: 0px 5.5rem;
            }

            ul {
                list-style-type: none;

                h4 {
                    color: $dark-mustard;
                    text-transform: uppercase;
                }

                p {
                    margin-top:5px;
                }

                li {
                    padding-bottom:2rem;
                }
            }
            
        }
    }
    
}