section.our-process {
    
    color: $white;
    background:$bg-gray;
    background-image:url(/assets/img/building.png);
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: contain;
    padding-bottom: 26rem;
    
    @media screen and (min-width: $mobile-BP) { 
        padding-bottom: 35rem;
    }

    @media screen and (min-width: $tablet-BP) {  
        padding-bottom: 45rem;
    }

    @media screen and (min-width: $desktop-BP) {  
        padding-bottom: 60rem;
    }

    @media screen and (min-width: $desktop-big-BP) { 
        padding-bottom: 75rem;
    }


    @media screen and (min-width: $desktop-extra-BP) { 
        padding-bottom: 90rem;
    }

    @media screen and (min-width: $desktop-extra-big-BP) { 
        padding-bottom: 108rem;
    }

    & > div {
        padding: 5rem 0rem 0 3rem;

        @media screen and (min-width: $tablet-BP) {
            padding: 10rem 6rem 4rem;
        }
        
        @media screen and (min-width: $desktop-BP) {
            padding-top: 10rem;
        }

        .grid {

            @media screen and (min-width: $desktop-BP) {
                display:grid;
                gap: 0px 0px;
                grid-template-columns: 1.1fr 1.2fr 0.1fr 1.4fr 1.1fr;
                grid-template-rows: 1fr 1fr 1fr;
                grid-template-areas:
                "area1 area1 . area2 area2"
                "area1 area1 . area2 area2"
                "area1 area1 . area2 area2";
            }
            & > div {
                &:first-child {
                    
                    @media screen and (min-width: $desktop-BP) {  
                        grid-area: area1;
                    }

                    @media screen and (min-width: $desktop-big-BP) { 
                        padding-left: 7rem;
                    } 

                    h3 {
                        text-transform: uppercase;
                        @media screen and (min-width: $tablet-BP) {  
                            font-size: 38px;
                        }
                    }
                }
            
                &:last-child {
                    @media screen and (min-width: $desktop-BP) { 
                        grid-area: area2;
                    } 

                    .description {
                        padding-bottom: 3rem;
                        @media(min-width: $tablet-BP) {
                            padding-bottom: 6rem;
                        }
                    }

                    ol {
                        counter-reset: item;
                        padding: 0px 0px 0rem 5rem;
                        list-style-type: square;
                        padding: 0 2rem;
                        margin-bottom: 4rem;   
                        font-size: 1.5rem;

                        @media(min-width: $tablet-BP) {
                            font-size: 1.7rem;
                        }
                        
                        @media screen and (min-width: $desktop-BP) {
                            font-size: 1.98rem;
                            margin-bottom: 6rem;
                            padding: 0 0rem 0 2.5rem;
                        }

                        ::marker {
                            color: $soft-mustard;
                            font-size: x-large;
                        }

                        @media screen and (min-width: $tablet-BP) {
                            padding:0;
                        }

                        & > li {
                            display: block;
                            position: relative;
                            &::before {
                                content: counter(item) "  ";
                                counter-increment: item;
                                position: absolute;
                                top: 0;
                                left: -5rem;
                                font-size: 6rem;
                                font-weight: bold;
                            }

                            h4 {
                                padding-bottom: 1.3rem;
                                text-transform: uppercase;
                            } 
                            

                            p {
                                margin-bottom: 1rem;
                                font-size: 1.5rem;
                                
                                @media screen and (min-width: $tablet-BP) {
                                    margin-bottom: 7rem;
                                    font-size: 1.7rem;
                                    padding-bottom: 1rem;
                                    margin: 8px 0px;
                                }
                                @media(min-width: $desktop-BP) {
                                    font-size: 1.98rem;
                                }
                            }

                            ul {
                                list-style-type: square;
                                padding-bottom: 2rem;

                                @media screen and (min-width: $tablet-BP) {
                                    padding-bottom: 6rem;
                                    padding-left: 2rem;
                                }
                                ::marker {
                                    color: $soft-mustard;
                                    font-size: x-large;
                                }

                                li {
                                    line-height: 1.5;
                                    padding: 1rem 0;
                                    font-family: $ff-serif;
                                    @media screen and (min-width: $desktop-BP) {
                                        padding: 1rem 0  0;
                                    } 
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}