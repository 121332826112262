section.challenges-article-hero {
    padding-top: 12rem;
    @media screen and (min-width: $tablet-BP) {
        padding-top: 15rem;
    }

    &::before {
        content: "";
        position: absolute;
        background: $soft-bg-mustard;
        top: 0;
        left: 0;
        right: 0;
        height: 20rem;
        z-index: -1;
        @media screen and (min-width: $tablet-BP) {
            height: 40rem;
            padding-top: 200px;
        }
    }

    & > div {
        margin-bottom: 3;
        padding: 0;
        @media screen and (min-width: $tablet-BP) {
            padding: 0 2em;
            margin-bottom: 5rem;
        }

        @media screen and (min-width: $desktop-BP) {
            margin-bottom: 0;
            padding: 0;
            padding: 4em 0;
        }

        @media screen and (min-width: $desktop-big-BP) {
            padding: 0 2em;
        }
    }
    
    .grid {
        @media screen and (min-width: $desktop-BP) {
            display: grid;
            grid-template-columns: 2fr 1fr; 
            grid-template-rows: 1fr;
            gap: 0px 3rem;
            padding: 9rem 6rem; 
        }
        @media screen and (min-width: $desktop-big-BP) {
            grid-template-columns: 2fr 0.7fr; 
        }

        & > div {
            margin-bottom: 4rem;

            @media(min-width: $desktop-BP) {
                margin-bottom: 0;
            }
            
            &:first-child {
                
                & > figure {
                    position: relative;
                    margin: 0;
                    img {
                        width: 100%;
                        height: auto;
                    }   
                    
                    & > span {
                        background: $dark-gradient;
                        height: 2rem;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        width: 100%;

                        @media screen and (min-width: $tablet-BP) {
                            right: 2rem;
                            height: 3rem;
                        }
                        @media screen and (min-width: $desktop-BP) {
                            height: 5rem;
                        }
                    }
                }

                .article-title {
                    h4 {
                        text-transform: initial;
                        padding-top: 5rem;
                    }
                }

                & > div {

                    & > h4 {
                        text-transform: uppercase;
                        font-family: $ff-serif;
                        margin: 0;
                        font-size: 23px;
                        margin-bottom: 2rem;
                        @media(min-width: $tablet-BP) {
                            font-size: 30px;
                        }
                        @media(min-width: $desktop-BP) {
                            font-size: 33px;
                        }
                    }

                    p {
                        margin-bottom: 3rem;
                        margin-top: 0;

                        @media screen and (min-width: $tablet-BP) {
                            margin-bottom: 5rem;
                        }
                        @media screen and (min-width: $desktop-BP) {
                            margin-bottom: 4rem;
                        }
                    }

                    .small {
                        text-transform: none;
                    }

                    ul {
                        list-style-type: square;
                        padding: 0 2rem;
                        margin-bottom: 4rem;   
                        font-size: 1.5rem;
                    
                        @media(min-width: $tablet-BP) {
                            font-size: 1.7rem;
                        }
                    
                        @media(min-width: $desktop-BP) {
                            font-size: 1.98rem;
                        }
                        

                        @media screen and (min-width: $desktop-BP) {
                            margin-bottom: 6rem;
                            padding: 0 0rem 0 2.5rem;
                        }

                        ::marker {
                            color: $soft-mustard;
                            font-size: x-large;
                        }

                        li {
                            line-height: 1.5;
                            padding: 1rem 0;
                            font-family: $ff-serif;

                            &:nth-child(1){
                                padding: 0;
                            }

                            &:nth-child(4){
                                padding: 0;
                            }

                            @media screen and (min-width: $desktop-BP) {
                                padding: 1rem 0  0; 
                            } 
                        }
                    }

                    a {
                        text-transform: uppercase;
                        font-size: 1.5rem;
                        background: url(/assets/img/arrow-solid.svg) no-repeat center left;
                        background-size: 1.8rem;
                        padding: 1rem 0 1rem 3rem;

                        &::before {
                            padding: 1rem 0 1rem 3rem;
                        }
                        
                        @media screen and (min-width: $tablet-BP) {
                            background-size: 2rem;
                        }
                        @media screen and (min-width: $desktop-BP) {
                            font-size: 1.7rem;
                        }
                    }
                }  
            }

            &:last-child {
                
                & > div {
                    background: $dark-gray;
                    text-align: center;
                    padding: 2rem 1rem 3rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    h3 {
                        text-transform: uppercase;
                        color:$dark-mustard;
                        margin: 0;
                    }

                    p {
                        color:$white;
                        margin: 0;
                        padding: 0rem 2rem 2rem;
                        margin:1rem 0; 
                    }
                }
            }
        }   
    }
}