section.we-draw-service-detail {

    overflow: hidden;

    & > div {
        position: relative;
        @media screen and (min-width: $tablet-BP) {
            padding-bottom: 4rem;
            padding-top: 0rem;
        }

        .line {
            @media screen and (min-width: $tablet-BP) {
                position: absolute;
                width: 18px;
                border: 1px solid $dark-mustard;
                transform: translateX(-50%);
            }
        }

        .line-1 {
            @media screen and (min-width: $tablet-BP) {
                top:-8rem;
                right: 5%;
                height: 527px;
                transform: rotate(46deg);
            }
            @media screen and (min-width: $desktop-BP) {
                top: -39rem;
                right: 5%;
                height: 995px;
                transform: rotate(54deg);
            }
        }

        .line-2 {
            @media screen and (min-width: $tablet-BP) {
                top: 9rem;
                right: -11%;
                height: 638px;
                transform: rotate(-66deg);
            }
            @media screen and (min-width: $desktop-BP) {
                top: -33rem;
                right: -11%;
                height: 1175px;
            }

            @media screen and (min-width: $desktop-big-BP) {
                top: -21rem;
                height: 1500px;
            }
        }

        .line-3 {
            @media screen and (min-width: $tablet-BP) {
                top: 18rem;
                right: 7%;
                height: 433px;
                transform: rotate(-36deg);
            }
            
            @media screen and (min-width: $desktop-BP) {
                top: -14rem;
                right: -11%;
                height: 1300px;
                transform: rotate(-53deg);
            }
        }
    }

    .grid {
        @media screen and (min-width: $tablet-BP) {
            display: grid;
            grid-template-columns: auto 3fr 1.7fr 1fr 1fr 1fr;
            gap: 0px 0px;
            grid-template-areas:
            ". p1 p1 p1 p1 ."
            ". p2 p2 . . .";
        }

        @media screen and (min-width: $desktop-BP) {
            grid-template-columns: 0.2fr 0.8fr 0.1fr 1fr 0.1fr;
        }

        & > div {
            &:first-child {
                grid-area: p2;

                p {
                    @media screen and (min-width: $tablet-BP) {
                        margin: 0;
                        padding-top: 12rem;
                        padding-bottom: 10rem;
                    }

                    @media screen and (min-width: $desktop-BP) {
                        padding-top: 8rem;
                        padding-bottom: 5rem;
                    }
                }
            }
        }
    }
}