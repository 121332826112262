section.hero {

    position: relative;
    padding-top: 87px;

    @media screen and (min-width: $desktop-BP) {
        padding-top: 144px;
    }
    
    &::before {
        
        content: "";
        position: absolute;
        background: $dark-gray;
        top: 0;
        left: 0;
        right: 0;
        height: 47%;
        z-index: 0;
        @media screen and (min-width: $tablet-BP) {
            height: 47%;
        }
        @media screen and (min-width: $desktop-big-BP) {
            height: 53%;
        }
    }

    .e-avatar {
        @media screen and (min-width: $desktop-BP) {
            padding: 0;
            position: absolute;
            background:url(/assets/img/ethos-outline.svg) no-repeat -42px -7px; // imagen e
            top: 28%;
            left: 0;
            right: 0;
            height: 64%;
            z-index: -2;
            padding-top: 9rem;
            background-size: 43rem;
            
        }

        @media screen and (min-width: $desktop-big-BP) {
            top: 34%;
        }
    }

    .hero-text {
        margin-bottom: 3rem;
        grid-area: text; 

        @media screen and (min-width: $tablet-BP) {
            margin-top: 1rem;
        }

        @media screen and (min-width: $desktop-BP) {
            margin-top: 5rem; 
            padding-right: 6rem;
        }
        @media screen and (min-width: $desktop-big-BP) {
            margin-top: 14rem;
            padding-right: 7rem;
        }
    }

    .hero-description {
        @media screen and (min-width: $desktop-BP) {
            font-size: 1.8rem;
            font-family: $ff-serif;
            line-height: 1.7rem;
        }
    }
    
    .hero-gradient {
        grid-area: image;
        @media screen and (min-width: $desktop-BP) { 
            padding: 0 2rem;
        }
    }

    .hero-image {
        
        & >div {
            position: relative;

            span {
                background: $dark-gradient;
                height: 2rem;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;

                @media screen and (min-width: $tablet-BP) {
                    height: 3rem;
                }

                @media screen and (min-width: $desktop-BP) {
                    height: 4rem;
                }
            }

            picture {
                height: 200px;
                overflow: hidden;
                display: block;

                @media screen and (min-width: $tablet-BP) {
                    height: 260px; 
                }  
                @media screen and (min-width: $desktop-BP) {
                    height: auto;
                    overflow: visible;
                }  

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
    }

    .grid {
        position: relative;
        z-index: 2;
        display: grid;
        grid-template-columns: 1fr;
        gap: 0px 0px;
        grid-template-areas:
        "text"
        "image";

        @media screen and (min-width: $tablet-BP) {
            padding: 2em 6em;
        }

        @media screen and (min-width: $desktop-BP) {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr;
            grid-template-areas:
            "text image";
        } 
    }
}