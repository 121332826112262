section.summary {
    & > div {
        padding: 1em 0;
        @media screen and (min-width: $tablet-BP) {
            padding: 0 2em;
        }
    }

    .grid {
        
        @media screen and (min-width: $tablet-BP) {
            padding: 0 6em;
        }

        @media screen and (min-width: $desktop-BP) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-rows: 1fr;
            gap: 0px 0px;
            grid-template-areas:
            "text text text .";
        }
        
        p {
            @media screen and (min-width: $desktop-BP) {
                grid-area: text;
                margin-top: 8rem;
                margin-bottom: 7rem;
            }
        }
    }
}