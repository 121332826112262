section.newsletter-subscription {
    
    background-color: $bg-mustard;

    @media screen and (min-width: $tablet-BP) {
        padding: 4rem 0;
    }

    @media screen and (min-width: $desktop-BP) {
        padding: 4rem 2rem;
    }

    & > div {
        padding: 4rem 0;
        .grid {
            &.generic-button,
            &.button-big {
                padding: 15px 40px 14px;
            }
            @media screen and (min-width: $tablet-BP) {
                padding: 2em 4em;
                text-align: center;
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
    
            @media screen and (min-width: $desktop-big-BP) {
                display: grid;
                grid-template-rows: 100%;
                grid-template-columns: 1.5fr auto;
                align-items: center;
                padding: 2em 6em;
            }

            & > div {
                &:first-child {
                    position: relative;

                    div.error {
                        position: absolute;
                        background-color: $soft-bg-mustard;
                        color: $black;
                        padding: 0.5rem 1rem;
                        margin: auto;
                        height: fit-content;
                        width: max-content;
                        left: 0;
                        right: 0;
                        bottom: -2.5rem;
                        &::before {
                            content: "";
                            position: absolute;
                            border-left: 0.5rem solid transparent;
                            border-right: 0.5rem solid transparent;
                            border-top: 0.5rem solid transparent;
                            border-bottom: 0.5rem solid $soft-bg-mustard;
                            top: -1rem;
                            left: 0;
                            right: 0;
                            width: 0px;
                            margin: auto;
                        }
                    }
                }
            }

            input[type="email"] {
                padding: 1.3rem;
                width: 100%;
                background: $soft-brown;
                border:none;
                margin-bottom: 1rem;
                color: $white;
                font-size: 1.9rem;
                text-align: center;
                font-family: $ff-serif;
                border-radius: inherit;
                
                @media screen and (min-width: $tablet-BP) {
                    font-size:2.5rem;
                    margin: 0;
                    // margin-bottom: 4rem;
                }
                
                @media screen and (min-width: $desktop-big-BP){
                    text-align: left;
                    font-size: 2.3rem;
                }
        
                &:focus {
                    outline: none;
                }
                
                @media screen and (min-width: $desktop-big-BP) {
                    width: 100%;
                    margin-bottom: 0;
                    padding-left: 3rem;
                }
            }

            input[type="submit"]{
                width: 100%;
                
                @media screen and (min-width: $tablet-BP) {
                    padding: 18px 8px 15px;
                    margin: 0;
                    font-size: 1.5rem;
                    height: 100%;
                }

                @media screen and (min-width: $desktop-BP) {
                    padding: 18px 18px 16px;
                    font-size: 1.7rem;  
                }

                @media screen and (min-width: $desktop-big-BP) {
                    padding: 18px 18px 13px;
                    font-size: 1.7rem;  
                }
            }
            
        }

        p.success-message {
            color: $soft-text;
            text-align: center;
            margin: 0;

            @media screen and (min-width: $tablet-BP) {
                padding: 0 4rem;
            }

            @media screen and (min-width: $desktop-BP) {
                padding: 0 6rem;
            }
        }
    }
}