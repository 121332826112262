section.your-company-service-detail {
    & > div { 
        text-align: center;
        line-height: 1.5;
        @media screen and (min-width: $tablet-BP) { 
            padding: 7rem 13rem;
        }

        @media screen and (min-width: $desktop-BP) { 
            padding: 8rem 13rem;
        }

        @media screen and (min-width: $desktop-big-BP) { 
            padding: 12rem 27rem;
            line-height: 1.5;
        }

        a {
            text-transform: uppercase;
        }
    }
}