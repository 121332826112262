section.quote {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 24%;
        background: $bg-mustard;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -2; 
    }

    & > div {
        background: -webkit-gradient($soft-gradient);
        background:$bg-dark-brown;
        margin-top: 0;
        padding: 1rem 2rem;

        @media screen and (min-width: $desktop-BP) {
            padding: 4rem 2rem;
        }

        @media screen and (min-width: $desktop-big-BP) {
            margin-top: 13rem;
        }
    }

    .grid {
        @media screen and (min-width: $tablet-BP) {
            margin-top: 1rem;
            margin-bottom: 2rem;
            padding: 0 6rem;
        }

        @media screen and (min-width: $desktop-big-BP) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-rows: 1fr;
            gap: 0px 0px;
            grid-template-areas:
            "text text text .";
            margin-top: 3rem;
            margin-bottom: 3rem;
            position: relative;

            &::before {
                content: "";
                background: url(/assets/img/quotes.svg) no-repeat top right;
                background-size: 222px;
                position: absolute;
                z-index: 2;
                top: 0;
                right:7rem;
                bottom: 0;
                width: 224px;
                height: 168px;
                margin: auto;
                filter: invert(32%) sepia(4%) saturate(3858%) hue-rotate(347deg) brightness(94%) contrast(98%);
            }
        }

        blockquote {
            text-indent: -0.7em;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @media screen and (min-width: $desktop-BP) {
                text-indent: -1.10em;
                grid-area: text;
            }

            p {
                margin-top: 0;
                @media screen and (min-width: $tablet-BP) {
                    line-height: 4rem; 
                }
            }

            cite {
                color: $soft-mustard;
                font-style: normal;
                text-indent: 0rem;
            }
        }
    }
}