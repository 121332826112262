section.be-in-the-know {

    text-align: center;
    background-color: $soft-bg-mustard;
    
    @media screen and (min-width: $desktop-big-BP) {
        background: url(/assets/img/blog-building-test-2.jpg) no-repeat 69% 13%;     
    }
    
    h3 {
        color: $dark-mustard;
        @media screen and (min-width: $desktop-BP) {
            margin-top: 6rem;
            margin-bottom: 4rem;
        }

        @media screen and (min-width: $desktop-big-BP) {
            margin-top: 12rem;
            margin-bottom: 6rem;
        }
    }

    ul {
        @media screen and (min-width: $desktop-big-BP) {
            margin-bottom: 4em;
        }
    }

    li {
        list-style-type: none;
        padding-top: 1rem;
        border-bottom: 2px solid $dark-mustard;
        padding-bottom: 3rem;

        &:first-child {
            padding-top: 0;
        }

        p {
            margin-bottom: 0;
            padding: 1.5rem;
        }

        @media screen and (min-width: $desktop-BP) {
            padding: 3rem 9rem 2rem;
        }
    }

    li:last-child {
        border: none;
    }
}