$base-blue: #3276e3;
$medium-blue: darken($base-blue, 15%);
$dark-blue: darken($base-blue, 20%);
$light-blue: lighten($base-blue, 5%);
$soft-blue: lighten($base-blue, 10%);

.cookies {
    display: none;
    .card-cookies {
        width: calc(100% - 2rem);
        bottom: 0;
        left: 0;
        padding: 2rem;
        background: $white;
        text-align: center;
        position: fixed;
        bottom: 0;
        left: 0;
        margin: 1rem;
        z-index: 3001;
        box-shadow: $shadow-light;

        @media screen and (min-width: $tablet-BP) {
            width: 60%;
        }
        @media screen and (min-width: $desktop-BP) {
            width: 50%;
        }
        @media screen and (min-width: $desktop-extra-big-BP) {
            width: 40%;
        }
        @media screen and (min-width: $desktop-max-big-BP) {
            width: 30%;
        }

        .header-cookies {
            font-weight: 300;
            margin-bottom: 10px;
        }

        .content-cookies {
            p {
                font-size: 1.6rem;
                margin-top: 0;
            }
        }

        .actions-cookies {
            @media screen and (min-width: $tablet-BP) {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            a,
            .negative-button,
            .positive-button {
                margin: 0 0.5rem;

                &:first-child {
                    margin-bottom: 1.5rem;
                }

                @media screen and (min-width: $tablet-BP) {
                    min-width: 15rem;
                    
                    &:first-child {
                        margin-bottom: 0;
                    }
                }
            }

            .negative-button {
                a {
                    background-color: $dark-gray;
                    color: $white;
                    padding: 18px 18px 13px; //new
                    text-decoration: none;
                    font-size: 1.4rem;
                    font-weight: 700;
                    letter-spacing: 2px;
                    display: block;
                    text-align: center;
                    line-height: 1.4; //new
                    font-family: $ff-sansSerif;
                    text-transform: uppercase;
                }
            }

            .positive-button {
                a {
                    background-color: $dark-mustard;
                    color: $white;
                    padding: 18px 18px 13px; //new
                    text-decoration: none;
                    font-size: 1.4rem;
                    font-weight: 700;
                    letter-spacing: 2px;
                    display: block;
                    text-align: center;
                    line-height: 1.4; //new
                    font-family: $ff-sansSerif;
                    text-transform: uppercase;
                }
                
            }
        }
    }
}