section.what-can-we-do {

    @media screen and (min-width: $tablet-BP) {
        padding-bottom: 6rem;
    }
    @media screen and (min-width: $desktop-BP) {
        padding-bottom: 2rem;
    }

    overflow:hidden;
    & > div {
        position: relative;

        .line {
            @media screen and (min-width: $tablet-BP) {
                width: 18px;
                position: absolute;
                border: 1px solid $dark-mustard;
                transform: translateX(-50%);
            }
        }

        .line-1 {            
            @media screen and (min-width: $tablet-BP) {
                top: 17rem;
                left: 4%;
                height: 701px;
                transform: rotate(-156deg);
            }
        }

        .line-2 {               
            @media screen and (min-width: $tablet-BP) {
                top: 4rem;
                left: -11%;
                height: 635px;
                transform: rotate(48deg);
            }
            @media screen and (min-width: $desktop-BP) {
                top: 1rem;
            }
        }

        .line-3 {
            @media screen and (min-width: $tablet-BP) {
                top: 24rem;
                left: 18%;
                height: 648px;
                transform: rotate(77deg);
            }
        }

        .line-4 {
            @media screen and (min-width: $tablet-BP) {
                top: 31rem;
                left: 1%;
                height: 485px;
                transform: rotate(31deg);  
            }
        }
        
        @media screen and (min-width: $tablet-BP) {
            padding: 4rem 0;
        }
        @media screen and (min-width: $desktop-BP) {
            margin-bottom: 12rem;
            padding: 2rem 6rem;
        }

        & > h3 {
            color: $dark-mustard;
            text-align: left;
            margin-top: 0;
            text-align: center;
            margin-bottom: 2rem;
            
            @media screen and (min-width: $tablet-BP) {
                margin-bottom: 3rem;
                // margin-bottom: 6rem;
                
            }

            @media screen and (min-width: $desktop-BP) {
                margin-top: 8rem;
                margin-bottom: 5rem; 
            }
        }

        .grid {
            @media screen and (min-width: $tablet-BP) {
                display: grid;
                grid-template-columns: 1.4fr 1fr;
                gap: 1rem;
            }

            @media screen and (min-width: $desktop-BP) {
                    gap: 2rem;
            }

            @media screen and (min-width: $desktop-big-BP) {
                gap: 8rem;
        }

            & > div {
                &:first-child {
                    z-index: 2;
                    position: relative;

                    @media screen and (min-width: $tablet-BP) {
                        padding: 0 2rem; 
                    }

                    @media screen and (min-width: $desktop-BP) {
                        padding: 0px 1rem;
                    }

                    @media screen and (min-width: $desktop-big-BP) {
                        padding: 0 0 0 7rem;
                    }

                    img {
                        display: none;

                        @media screen and (min-width: $tablet-BP) {
                            margin-bottom: 0;
                            margin-top: 0;
                            display: block;
                            padding: 0;
                            height: 100%;
                            width: 95%;
                            object-fit: cover;
                            opacity: 0;
                            position: absolute;
                            top: 0;
                            left: 0;
                            transition: all 200ms ease;
                            
                            &.active {
                                opacity: 1;
                                transition: all 200ms ease;
                            }
                        }

                        @media screen and (min-width: $desktop-BP) {
                            right: 0;
                            width: auto;
                            object-fit: none;
                            left: auto;
                        }

                    }
                }

                &:last-child {
                    text-align: center;
                    @media screen and (min-width: $desktop-BP) {
                        padding: 0;
                    }

                    @media screen and (min-width: $desktop-big-BP) {
                        padding: 0 4rem 0 0;
                        text-align: left;
                    }

                    ul {
                        list-style-type: none;
                        @media screen and (min-width: $tablet-BP) {
                            text-align: left;
                            display: flex;
                            height: 100%;
                            flex-direction: column;
                            justify-content: center;
                        }

                        li {
                            padding-bottom: 2rem;
                            @media screen and (min-width: $tablet-BP) {
                                padding: 0;
                                &:nth-child(2) {
                                    margin: 3rem 0;
                                }
                            }
                            a {
                                line-height: 1.4;
                                text-transform: uppercase;
                            }
                            p {
                                margin-top: 0.5rem;
                                @media screen and (min-width: $tablet-BP) {
                                    margin: 0;
                                    margin-top: 1rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    } 
}