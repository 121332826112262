/*----------------------------------------------------------------*\
		MAIN NAV
\*----------------------------------------------------------------*/

header {
	top: 0;
	left: 0;
	width: 100%;
	z-index: 3;
	position: fixed;
	
	@media screen and (min-width: $desktop-BP) {	
		position: absolute;
		background: none;
	}
	.skip-content{
		position: absolute;
		left: -10000px;
		top: auto;
		width: 1px;
		height: 1px;
		overflow: hidden;
		z-index: 10;

		&:focus{
			position: absolute;
			left: 0;
			top: 0;
			width: auto;
			height: auto;
			overflow: hidden;
		}
	}
	
	nav.main-nav {
		&.home, &.whyus {
			ul {
				li {
					&:hover ul {	
						@media screen and (min-width: $tablet-BP) {		
							background: $dark-gray;
						}
					}
				}
			}
		}
		
		&.home, &.whyus, &.executive-search, &.research, &.talent-management-consulting,&.blog {  
			ul {
				li {
					a {
						color: $white;
						&.active {
							color: $dark-mustard;
						}
					}
					ul {
						li {
							&.active {
								a {
									border-bottom: 1px solid $white;
								}
							}
							&.hvr-underline-from-left-little{
								a{
									&::before {
										background: $white;
									}
								}
							}
						}
					}
				}
			}
		}
		
		&.services,
		&.challenges,
		&.contact {
			ul {
				li {
					a {
						color: $white;
						@media screen and (min-width: $desktop-BP) {
							color: $black;
						}
						&.active {
							color: $dark-mustard;
						}
					}
					ul {
						li {
							&.hvr-underline-from-left-little{
								a{
									&::before {
										@media screen and (min-width: $desktop-BP) {
											background: $black;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		&.blog {
			.logo-burger {
				background-color: $bg-gray !important;
			}
			@media screen and (min-width: $desktop-BP) {		
				background-color: $bg-gray;
			}
		}

		.nav {
			position: fixed;
			background: $black;
			top: -100%;
			left: 0;
			right: 0;
			height: 100%;
			padding: 12rem 3rem 5rem 3rem;
			z-index: -1;
			transition: top .5s ease;

			&.open {
				top: 0;
				transition: top .5s ease;
			}
			@media screen and (min-width: $tablet-BP) {
				padding: 20rem 3rem 5rem 5rem;
			}
			@media screen and (min-width: $desktop-BP) {		
				margin-right: 11rem;
				display: block;
				padding: 0;
				position: static;
				top: auto;
				background: none;
				right: auto;
				left: auto;
				z-index: 1;
			}

			& > ul {
				list-style-type: none;
				display: flex;
				flex-direction: column;
				@media screen and (min-width: $desktop-BP) {	
					gap: 2rem;
					list-style-type: none;
					display: flex;
					flex-direction: row;
					align-items: center;
				}

				& > li {
					display: inline-block;
					margin-bottom: 2rem;
					.border-m {
						border-bottom: 1px solid $dark-mustard;
						width: max-content;

						@media screen and (min-width: $desktop-BP) {
							border: none;
						}
					}

					@media screen and (min-width: $tablet-BP) {
						position: relative;
						padding-right: 3.8rem;
						transition-duration: 0.5s;
					}

					@media screen and (min-width: $desktop-BP) {
						margin: 0;
					}

					a {
						text-decoration: none;
						line-height: 1.5;
						font-size: 20px;

						@media screen and (min-width: $tablet-BP) {
							padding-bottom: 0.7rem;
						}
						@media screen and (min-width: $desktop-BP) {
							font-size: 16px;
						}
					}

					&:nth-child(0n+2){
						&:hover > a {
							@media screen and (min-width: $tablet-BP) {
								color:$dark-mustard;
							}
						}
					}

					&:hover li {
						@media screen and (min-width: $tablet-BP) {
							color:$dark-mustard;
						}
					}

					ul {
						list-style-type: square;
						padding-left: 2rem;

						@media screen and (min-width: $desktop-BP) {
							position: absolute;
							width: 170px;
							left: -14px;
							max-height:0;
							overflow: hidden;
							-webkit-transition: max-height 0.4s linear;
							-moz-transition: max-height 0.4s linear;
							transition: max-height 0.4s linear;
							z-index: 3;
							padding-top: 20px;
							padding-left: 14px;
							padding-right: 14px;
						}

						@media screen and (min-width: $desktop-BP) {
							padding-left: 0;
							list-style-type: none;
							z-index: 3;
						}

						li {
							margin-bottom: 0.5rem;
							margin-top: 1rem;
							font-size: 2rem;
							color: $dark-mustard;

							@media screen and (min-width: $desktop-BP) {
								
								margin-top: 0.5rem;
								color: inherit;
								padding-left: 1.4rem;
								padding-bottom: 0.2rem;
								a {
									font-size: 14px;
								}
							}
						}
					}

					&:hover ul {	
						@media screen and (min-width: $tablet-BP) {		
							max-height: 220px;
						}
					}
				
				}
			}
		}

		.flex {
			@media screen and (min-width: $desktop-BP) {		
				display:flex;
				justify-content: space-between;
				align-items: center;
				padding-right: 2rem;
				padding-right: 0;
			}

			.logo-burger {
				display:flex;
				justify-content: space-between;
				align-items: center;
				padding: 2em;
				background: $black;
				@media screen and (min-width: $tablet-BP) {		
					padding: 1rem 4.6rem;
				}
				@media screen and (min-width: $desktop-BP) {		
					padding: 3rem 4.2rem;
					background: none;
				}
				@media screen and (min-width: $desktop-big-BP) {		
					padding: 3rem 10rem;
				}
			}
		}

		.menu-burger {
			.lines-burger{
				display:flex;
				flex-direction:column;
				width:4rem;
				cursor:pointer;

				span{
					background: $dark-mustard;
					height: 2px;
					margin: 5px 0;
					transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);
				}

				span:nth-of-type(1){
					width:46%;
				}

				span:nth-of-type(2){
					width:94%;
				}

				span:nth-of-type(3){
					width:56%;
				}

				input[type="checkbox"]{
					display:none;
				}

				input[type="checkbox"]:checked ~ span:nth-of-type(1){
					width: 47%;
					transform-origin:bottom;
					transform:rotatez(45deg) translate(5px, 1px)
				}

				input[type="checkbox"]:checked ~ span:nth-of-type(2){
					transform-origin:top;
					transform:rotatez(-45deg)
				}
				
				input[type="checkbox"]:checked ~ span:nth-of-type(3){
					transform-origin:bottom;
					width: 47%;
					transform: translate(17px, -6px) rotatez(45deg);
				}
			}
		}
	}
}