div.form-contact {
    max-width: 1200px;
    margin: 0 auto;
    padding-left:2rem;
    padding-right: 2rem;
    @media screen and (min-width: $tablet-BP) {
        padding-top: 2rem;
        padding-left:4rem;
        padding-right: 4rem;
    }
    & > div {
        padding: 6rem 0; //new

        @media screen and (min-width: $tablet-BP) {
            padding: 11rem 0; //new
        }

        @media screen and (min-width: $desktop-BP) {
            padding: 4rem 0; //new
        }

        &:first-child {
            & > div {
                text-align: center;
                margin-bottom: 4rem; //new

                @media screen and (min-width: $tablet-BP) {
                    margin-bottom: 7rem;
                }
    
                h4 {
                    text-transform: uppercase;
                    margin-top: 0;
                }
                p {
                    @media screen and (min-width: $tablet-BP) {
                        padding: 0 11rem;
                    }
                    @media screen and (min-width: $desktop-BP) {
                        padding: 0 25rem;
                    }
                    @media screen and (min-width: $desktop-big-BP) {
                        padding: 0 34rem;
                    }
                }
            }
        }

        & > form {
            .form-fields { // fields
                @media screen and (min-width: $tablet-BP) {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: repeat(3, 1fr);
                    gap: 1rem 3rem;
                }
                @media screen and (min-width: $desktop-BP) {
                    gap: 5rem;
                }
                
                & > div {
                    position: relative;
                    margin-bottom: 3rem;
                    @media screen and (min-width: $desktop-BP) {
                        margin: 0;
                    }
                    & > input {
                        appearance: none;
                        border: none;
                        border-bottom: 2px solid $dark-mustard;
                        border-radius: inherit;
                        width: 100%;
                        padding: 1rem;
                        outline: none;
                        transition: all 1s ease-out;
                        font-family: $ff-serif;
                        font-size: 1.9rem;
                    
                        @media screen and (min-width: $tablet-BP) {
                            font-size: 2rem;
                        }

                        @media screen and (min-width: $desktop-BP) {
                            font-size: 2.2rem;
                        }

                        &:focus {
                            border-bottom:2px solid $black;
                        }

                        &::placeholder {
                            color: $gray;
                            text-align: left;
                            font-size: 1.9rem;
                            border-radius: inherit;

                            @media screen and (min-width: $tablet-BP) {
                                font-size: 2rem;
                            }

                            @media screen and (min-width: $desktop-BP) {
                                font-size: 2.2rem;
                            }
                        }
                    }

                    div.error {
                        position: absolute;
                        background-color: $soft-bg-mustard;
                        color: $black;
                        padding: 0.5rem 1rem;
                        margin: auto;
                        height: fit-content;
                        width: max-content;
                        left: 0;
                        right: 0;
                        bottom: -2.5rem;
                        &::before {
                            content: "";
                            position: absolute;
                            border-left: 0.5rem solid transparent;
                            border-right: 0.5rem solid transparent;
                            border-top: 0.5rem solid transparent;
                            border-bottom: 0.5rem solid $soft-bg-mustard;
                            top: -1rem;
                            left: 0;
                            right: 0;
                            width: 0px;
                            margin: auto;
                        }
                    }

                }
            }
            .message { // textarea
                margin-top: 5rem;
                @media screen and (min-width: $tablet-BP) {
                    margin-top: 3rem;
                }
                @media screen and (min-width: $desktop-BP) {
                    margin-top: 5rem;
                }
                & > textarea {
                    width: 100%;
                    border: 2px solid $dark-mustard;
                    border-radius: inherit;
                    resize: none;
                    min-height: 15rem;
                    font-family: $ff-serif;
                    font-size: 2.2rem;
                    outline: none;
                    padding: 1rem;
                    font-size: 1.9rem;
                    appearance: none;

                    @media screen and (min-width: $tablet-BP) {
                        font-size: 2rem;
                    }

                    @media screen and (min-width: $desktop-BP) {
                        font-size: 2.2rem;
                    }
                }
            }

            .captcha-check {
                margin-top: 4rem;
                .g-recaptcha {
                    & > div {
                        margin: 0 auto;
                    }
                }

                .freeform-form-has-errors {
                    text-align: center;
                    margin-top: 2rem;
                    font-size: 1.6rem;
                }
            }

            .cta {
                margin: 4rem 0 6rem 0;
                & > input {
                    margin: auto;
                    appearance: none;
                    outline: none;

                    &:disabled {
                        opacity: 0.6;
                    }
                }
                .success-message {
                    text-align: center;
                }
            }
            
        }
    }
}