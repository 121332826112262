section.hero-challenges {

    & > div {
        padding: 15rem 0 0 0;
        
        @media screen and (min-width: $desktop-BP) {
            padding-top: 24rem;
            padding-bottom: 21rem //new
        }

        .grid {
            @media screen and (min-width: $tablet-BP) { 
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: 1fr;
                gap: 0px 0px;
                grid-template-areas:
                "animation text text";
            }

            @media screen and (min-width: $desktop-BP) {
                grid-template-columns: 1.1fr 1.2fr 0.1fr 1.4fr 1.1fr;
                grid-template-rows: 1fr 1fr 1fr;
                grid-template-areas:
                "animation animation . text text"
                "animation animation . text text"
                "animation animation . text text";
            }

            & > div {
                &:first-child {
                    grid-area: animation;
                    position: relative;
                }

                &:last-child {
                    grid-area: text;
                    
                    @media screen and (min-width: $desktop-BP) { 
                        padding-right: 3.5rem;
                    } 
                    
                    & > div {
                        &:first-child {
                            position: relative;

                            h4 {
                                @media screen and (min-width: $tablet-BP) { 
                                    padding-bottom: 3.5rem;
                                    margin: 0;
                                }
                            }

                            p {
                                margin-bottom: 5rem; //new

                                @media screen and (min-width: $tablet-BP) { 
                                    margin-bottom: 3rem;
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                }
            }

            .line {
                @media screen and (min-width: $tablet-BP) {
                    position: absolute;
                    width: 18px;
                    border: 1px solid $dark-mustard;
                    transform: translateX(-50%);
                }
            }

            .line-1 {
                @media screen and (min-width: $tablet-BP) {
                    top: -22%;
                    left: 8%;
                    height: 544px;
                    transform: rotate(-22deg);
                    
                }

                @media screen and (min-width: $desktop-BP) {
                    top: -5%;
                    left: 33%;
                    height: 747px;
                }
            }

            .line-2 {
                @media screen and (min-width: $tablet-BP) {
                    top: -3%;
                    left: 1%;
                    height: 421px;
                    transform: rotate(48deg);
                }

                @media screen and (min-width: $desktop-BP) {
                    top: -69%;
                    left: 18%;
                    height: 690px;
                    transform: rotate(48deg);
                }
            }

            .line-3 {
                @media screen and (min-width: $tablet-BP) {
                    top: -34%;
                    left: 31%;
                    height: 320px;
                    transform: rotate(230deg);
                    
                }
                @media screen and (min-width: $desktop-BP) {
                    height: 775px;
                }
            }
        }
    }
}