/*----------------------------------------------------------------*\
		BUTTONS
\*----------------------------------------------------------------*/
button,
.button,
input[type="submit"] {
	cursor: pointer;
	margin: 0.5em 0;
	padding: 0.75em 1em;
	box-shadow: none;
	border: none;
	border-radius: 0;
	position: relative;
	// background-color: $red;
	font-size: 20px;
	letter-spacing: 2px;
	// font-weight: bold;
	color: $white;
    text-decoration: none;
    font-family: $ff-serif;
	text-transform: uppercase;
	
	@media screen and (min-width: $tablet-BP) {
		font-size: 24px;	
	}
}

.button-container {
	width: 100%;
	@media screen and (min-width: $tablet-BP) {
		width: auto;
	}
	.generic-button {
		background-color: $dark-gray;
		color: $soft-mustard;
		padding: 18px 18px 13px; //new
		text-decoration: none;
		font-size: 1.4rem;
		font-weight: 700;
		letter-spacing: 2px;
		display: block;
		text-align: center;
		line-height: 1.4; //new
		font-family: $ff-sansSerif;
		text-transform: uppercase;
		
		@media screen and (min-width: $mobile-BP) {
			padding: 19px 35px;
		}
	
		@media screen and (min-width: $tablet-BP) {
			padding: 18px 40px 14px;
			font-size: 1.7rem;
		}
	
		&.button-big {
			max-width: 100%;
		}
		&.button-small {
			@media screen and (min-width: $tablet-BP) {
				max-width: 180px;
				display: inline;
			}
			@media screen and (min-width: $desktop-BP) {
				display: block;
			}
		}
		&.button-long {
			@media screen and (min-width: $tablet-BP) {
				max-width: 278px;
			}
		}
	
		&.input-button {
			color: $soft-mustard;
			padding: 14px 50px;
			font-size: 16px;
		}
	}
	
	.white-button {
		line-height: 1.6;
		background-color: $white;
		color: $black;
		padding: 20px 35px 15px 35px;
		text-decoration: none;
		font-size: 1.4rem;
		font-weight: 700;
		letter-spacing: 2px;
		display: block;
		max-width: 614px;
		margin: 0 auto;
		text-transform: uppercase;
		font-family: $ff-sansSerif;
	
	
		@media screen and (min-width: $tablet-BP) {
			padding: 20px 15px 15px 15px;
			font-size: 1.7rem;
		}
	}
}

